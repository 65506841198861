import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { reportNarrative } from 'state/actions/narrativeActions';

export default () => {
  const dispatch = useDispatch();
  const { status, error } = useStatus(reportNarrative);

  const { showErrorToast, showToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('The content was successfully reported!');
      dispatch(reportNarrative.reset());
    }
    if (status === ERROR) {
      showErrorToast('Cannot report this content again with the same reason');
      dispatch(reportNarrative.reset());
    }
  }, [dispatch, showErrorToast, showToast, status, error]);

  return useCallback(
    ({ narrativeId, params }) => dispatch(reportNarrative({ narrativeId, params })),
    [dispatch]
  );
};
