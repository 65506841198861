import Close from 'components/common/Close/Close';
import React from 'react';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';

const InfoModal = ({ hide, id }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        Information <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <div className="info-modal-para">
          <b>Available balance</b> is calculated with your total revenues minus administration fees.
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
