import dayjs from 'dayjs';

export const getDayAndMonth = date => {
  return dayjs(date).format('DD MMM');
};

export const getDiffDay = date => {
  const days = Math.abs(dayjs().diff(new Date(date), 'day'));
  return days;
};

export const getReleaseDate = date => {
  return dayjs(date).format('DD:MM:YYYY');
};

export const timeToSeconds = time => {
  const [hours, minutes, seconds] = time.split(':');
  return Number(hours) * 360 + Number(minutes) * 60 + Number(seconds);
};

export const getWrittenDate = date => {
  return dayjs(date).format('MMM D h:mm A');
};

export const calculateTimeLeft = date => {
  const futureDate = dayjs(date);
  const now = dayjs();

  const days = futureDate.diff(now, 'days');
  const hours = futureDate.diff(now, 'hours');
  const minutes = futureDate.diff(now, 'minutes');
  const seconds = futureDate.diff(now, 'seconds');

  const data = {
    days,
    hours: hours - days * 24,
    minutes: minutes - hours * 60,
    seconds: seconds - minutes * 60
  };

  return data;
};

export const getDateTime = (date, time) => {
  if (!date) return undefined;

  const dateTime = dayjs(time);
  const hours = dateTime.hour();
  const minutes = dateTime.minute();
  const newDateTime = dayjs(date)
    .set('hours', hours)
    .set('minutes', minutes);
  return newDateTime;
};
