export const routesPaths = {
  home: '/',
  celebrityId: '/id/:username',
  series: '/series/:id/:episode?',
  narratives: '/narratives/:celebrityId/:id?',
  category: '/category/:slug',
  login: '/login',
  signup: '/signup',
  verifyPhone: '/verify',
  legal: '/legal/:tab',
  live: '/live/:celebrityId',
  account: '/account/:tab?',
  scheduledLive: '/identifi-live/:id',
  becomeCreator: '/become-creator',
  addNarratives: '/add-narratives',
  addOriginals: '/add-originals',
  metrics: '/metrics'
};

export const invRoutes = {
  '/': 'home'
};
