/* eslint-disable no-template-curly-in-string */

export default {
  // Navbar
  'navbar.home': 'Home',
  'navbar.privacyTerms': 'Privacy Policy',
  'navbar.subscriptions': 'Subscriptions',
  'navbar.paymentMethod': 'Payment Method',
  'navbar.settings': 'Settings',
  'navbar.termsOfService': 'Terms of Service',
  'navbar.linkOfInterest': 'Links of Interest',
  'navbar.goLive': 'Go Live',
  'navbar.upload': 'Upload',
  'navbar.dm': 'DM',

  // Content types
  'content.original': 'Original',

  // Footer
  'footer.social': 'Social',
  'footer.content': 'Content',
  'footer.legal': 'Legal',
  'footer.contactUs': 'Contact Us',
  'footer.support': 'Support',
  'footer.mediaInquiries': 'Media & Partnership Inquiries',

  // Featured
  'featured.popularNow': 'Popular Now',
  'featured.mostWatched': 'Most Watched',
  'featured.creators': 'Featured Creators',

  // General
  'general.back': 'Back',
  'general.close': 'Close',
  'general.more': 'more',
  'general.less': 'less',
  'general.explore': 'Explore',
  'general.keepWatching': 'Keep Watching',
  'general.info': 'INFO',
  'general.loading': 'Loading..',
  'general.continue': 'CONTINUE',
  'general.cancel': 'Cancel',
  'general.add': 'Add',
  'general.yes': 'Yes',
  'general.creators': 'Creators',
  'general.episode': 'Episode',
  'general.total': 'Total',
  // Date
  'date.days': '{amount, plural, one {day} other {days}}',
  'countdown.dayHour':
    '{days}{days, plural, one {day} other {days}} {hours}{hours, plural, one {hour} other {hours}}',
  'countdown.hours': '{amount}{amount, plural, one {hour} other {hours}}',
  'countdown.hasStarted': 'Live event will start soon..',

  // CreatorId
  'tab.originals': 'Originals',
  'tab.narratives': 'Narratives',
  'tab.episodes': 'Episodes',
  'tab.details': 'Details',
  'tab.payments': 'Payment Method',
  'tab.subscriptions': 'Subscriptions',
  'tab.settings': 'Settings',
  'tab.privacy': 'Privacy Policy',
  'tab.terms': 'Terms of Service',
  'tab.linkOfInterest': 'Links of Interest',
  'tab.manageAccount': 'Manage My Account',
  'tab.changeProfileImage': 'Change Profile Image',
  'celebrity.shop': 'Shop',

  // Serie landing
  'serie.creator': 'Creator',
  'serie.categories': 'Categories',
  'serie.episodeUnavailable': 'This episode is no longer available',

  // Narratives
  'narratives.narrativeUnavailable': 'This narrative is no longer available',

  // Episodes
  'episodes.first': 'Episode 1',
  'episodes.play': 'Play',
  'episodes.all': 'Episodes',
  'episodes.watch': 'WATCH',
  'episodes.abbreviation': 'eps',
  'episodes.resume': 'Resume (ep. {num})',
  'episodes.nextEpisode': 'Next Episode ',
  'episodes.playEpisode': 'PLAY EPISODE ',
  'episodes.goToId': 'Go to {name}’s ID',

  'common.play': 'PLAY',

  // Videos
  'duration.minutes': 'm',

  // Fields
  'form.phoneNumber': 'Phone Number',
  'form.phoneNumber.placeholder': '(555) 555-5555',
  'form.errors.phoneNumber': 'The phone number is not valid',
  'form.signin.submit': 'SIGN IN',
  'form.signup.submit': 'SIGN UP',
  'form.name': 'Name',
  'form.name.placeholder': 'Name',
  'form.lastName': 'Last Name',
  'form.lastName.placeholder': 'Last Name',
  'form.email': 'Email',
  'form.email.placeholder': 'John@gmail.com',

  // creator form fields
  'form.username': 'Username',
  'form.bio': 'Bio Description (optional)',
  'form.price': 'Initial Tier Membership price',
  'form.profile_image': 'Profile Image',
  'form.usernamePlaceholder': 'Username',
  'form.bioPlaceholder': 'Tell others a little about you.',
  'form.pricePlaceholder': 'Example: $25',
  'form.profilePicture': 'Profile Picture',
  'form.banner': 'Cover Picture',
  'form.creator.submit': 'BECOME A CREATOR',
  'form.creator.success': 'You are now a creator!',
  'form.uploadProfilePicture': 'Upload Profile Picture',
  'form.uploadCoverPicture': 'Upload Cover Picture',
  'title.creator': 'Become a Creator!',
  'subTitle.creator':
    'Be part of the Identifi creator community. Start browsing and sharing your productions with the rest of the community.',

  // Verify phone
  'verifyPhone.title': 'Verify Phone',
  'verifyPhone.enterCode': 'Enter code',
  'verifyPhone.subtitle': 'We sent you a text message with a 5-digit verification code.',
  'verifyPhone.submit': 'VERIFY PHONE',
  'verifyPhone.resendCode': 'RESEND VERIFICATION',
  'verifyPhone.code.error':
    'Verification code doesn’t match, double check your phone number or hit resend button below.',
  'verifyPhone.sendCodeSuccess': 'Verification code successfully sent!',
  'verifyPhone.resendCodeError': "We couldn't send your verification code, please try again.",

  // Users
  'user.signin': 'Sign In',
  'user.logout': 'Log Out',
  'user.createAccount': 'Create Account',
  'user.createAccount.success': 'Your account was successfully created, welcome!',
  'user.signup': 'Sign Up',
  'user.existingAccount': 'I have an account',
  'user.follow': 'Follow',
  'user.following': 'Following',
  'account.title': 'Account',
  'user.needAccount': 'You need an account to continue.',

  // Notifications
  'notifications.title': 'Notifications',
  'notifications.empty': 'No new notifications',
  'notifications.empty.message':
    'Why don’t you start following a few celebrities, and you will start getting notifs of content you like',
  'notifications.dm.title': 'DM from {name}',
  'notifications.liveTag': 'LIVE',
  'notifications.episodeTag': 'NEW EP',
  'notifications.narrativesTag': 'NEW',
  'notifications.earlyTag': 'EARLY ACCESS',
  'notifications.exclusiveTag': 'EXCLUSIVE',
  'notifications.scheduleLiveTag': 'LIVE EVENT',
  'notifications.seriesTag': 'NEW SERIES',

  // Not found page
  'notfound.title': 'Oops!',
  'notfound.subtitle': 'Something went wrong!',
  'notfound.message1': 'Double check the link',
  'notfound.message2': 'or you can...',
  'notfound.goHome': 'GO HOME',

  // Privacy policy
  'privacyPolicy.title': 'Privacy Policy',
  'privacyPolicies.name': 'Privacy Policies',

  // Terms of service
  'terms.title': 'Terms of Service',

  // Legal messages
  'legalMessages.subscribing': 'By subscribing you acknowledge our ',
  'legalMessages.purchasing': 'By purchasing you acknowledge our ',
  'legalMessages.message2': ' and acknowledge our ',
  'legalMessages.message3': ' applies.',
  'legalMessages.signUp': 'By clicking “Sign Up" button, you accept all ',
  'legalMessages.signUp2': ' and ',
  'legalMessages.creator': 'By clicking “Become a creator" button, you accept all the ',
  'legalMessages.creator2': ' and ',

  // Share
  'sharing.episode': 'Hey! Check this episode of {seriesTitle} on Identifi ',
  'sharing.narrative': 'Hey! Check this narrative from {creator} on Identifi ',

  // Live events
  'live.isLive': 'Live',
  'live.offline': 'Offline',
  'live.offline.subtitle': 'But hey! Come check the amazing content available',
  'live.offline.button': 'GO TO ID',
  'live.offline.title': 'Creator is offline',
  'live.ended.title': 'Event has ended ',
  'live.ended.button': 'GO BACK',

  // Live events
  'live.watch': 'Watch Live',

  // Membership
  // TODO: add correct benefits description.
  'membership.title': 'Membership',
  'membership.price': 'Renews monthly at ${price}',
  'membership.monthly.price': '${price}/mo',
  'membership.subscribe': 'Subscribe',
  'membership.subscribed': 'Member',
  'membership.subscribePrice': 'Subscribe | ${price}/{period}',
  'membership.subscribeCelebrityPrice': 'Subscribe to {name} | ${price}/{period}',
  'membership.subscribeCelebrity': 'Subscribe to {name}',
  'membership.description':
    'Support and follow {name} to receive exclusive subscriber benefits. Get the newest, best, completely exclusive content by subscribing now.',
  'membership.benefits': 'Benefits',
  'membership.celebrityBenefits': 'Subscription Benefits',
  'membership.celebritySubscription': '{name} Subscription',
  'membership.cancellationMessage': 'Subscriptions can be canceled at any time.',
  'membership.complete.to': 'Membership to',
  'membership.pricePerPeriod': '${price}/{period}',
  'membership.completeSubscription': 'COMPLETE SUBSCRIPTION',
  'membership.createPaymentMethod': 'ADD PAYMENT METHOD',
  'membership.create.success': 'Membership successfully created',
  'membership.cancel.success': 'Successfully canceled membership',
  'membership.changeMethod': 'Change Method',
  'membership.renew': 'Membership will renew at ${price}',

  // Membership benefits
  'membership.firstBenefitTitle': 'Exclusive content',
  'membership.firstBenefitDescription':
    'Watch premium content from {firstName} you can’t find anywhere else.',
  'membership.secondBenefitTitle': 'Early Access',
  'membership.secondBenefitDescription':
    'Get exclusive, early access to {firstName}’s new Episodes and Narratives.',
  'membership.thirdBenefitTitle': 'Member Notifications',
  'membership.thirdBenefitDescription':
    'Get closer than ever before with exclusive updates and DM’s exclusive to {firstName}’s subscribers.',
  'membership.fourthBenefitTitle': 'Ad Free Viewing',
  'membership.fourthBenefitDescription': 'Enjoy {firstName}’s ID to the fullest without any ads.',
  'membership.fifthBenefitTitle': 'Custom Badges',
  'membership.fifthBenefitDescription':
    'Flaunt your super fandom with a Membership badge for all of your live chats and sharing',

  // Membership form
  'membership.form.creditCardInfo': 'Credit card information',
  'membership.form.billingInfo': 'Billing information',
  'form.creditCard': 'Credit card number',
  'form.creditCard.placeholder': 'Credit card number',
  'form.expiryDate': 'Expire date',
  'form.expiryDate.placeholder': '10/20',
  'form.cvv': 'CVV',
  'form.cvv.placeholder': 'CVV',
  'form.country': 'Country',
  'form.country.placeholder': 'Country',
  'form.zipcode': 'Zipcode',
  'form.zipcode.placeholder': 'Zipcode',

  // Subscriptions
  'subscriptions.title': 'Subscriptions',
  'subscriptions.empty': 'You don’t have any subscriptions',
  'subscriptions.cancel': 'Cancel subscription',
  'subscriptions.confirmCancel': 'Sure you want to Unsubscribe?',
  'subscriptions.subscribeTo': 'Subscribe to',
  'membershipCard.periodLabel': 'Renews {period}. Next on ',
  'membershipCard.timeToNextPayment': '(in {expiry})',
  'membershipCard.canceled': 'Canceled',
  'membershipCard.activeUntil': 'Active until',

  // Payments
  'paymentMethod.title': 'Payment Method',
  'paymentMethod.new': 'New Payment Method',
  'payments.changeMethod': 'Change Method',
  'payments.form.creditCard': 'Credit card',
  'payments.completePurchase': 'Complete Purchase',

  // Credit Cards
  'creditCard.addCard': 'Add Card',
  'creditCard.default.label': 'This card will be used for all future subscription payments',
  'creditCard.others': 'Other cards you’ve added',
  'creditCard.default.confirmation':
    'Are you sure you want to use this card for all future subscription payments?',
  'creditCard.setAsDefault': 'SET AS DEFAULT',
  'creditCard.empty': 'You don’t have any credit cards',
  'creditCard.success': 'Credit card added successfully',

  // Early release
  'earlyRelease.warning': 'This is an early release available to subscribers only',
  'earlyRelease.watchAnother': 'Watch other episodes',
  'earlyRelease.checkBackIn': 'Check back when publicly available:',

  // Exclusive
  'exclusive.warning': 'Please subscribe to {br} {creatorName} to continue',

  // Scheduled Live Hero
  'scheduledLive.tag': 'LIVE',
  'scheduledLive.trailer': 'TRAILER',
  'scheduledLive.liveEvent': 'LIVE EVENT',
  'scheduledLive.liveNow': 'LIVE NOW',
  'scheduledLive.watch': 'WATCH LIVE',
  'scheduledLive.started': 'Event Started',

  // Pay per view
  'payPerView.buy': 'BUY',
  'payPerView.buyNow': 'Buy Now | ${price}',
  'payPerView.buyWithPrice': 'BUY | ${price}',
  'payPerView.watchLivePrice': 'WATCH LIVE | ${price}',
  'payPerView.watch': 'WATCH',
  'payPerView.message': 'Once payment is processed you will have live access to the event.',
  'payPerView.secondMessage':
    'All Pay-Per-View events are non-refundable unless canceled by Identifi.',
  'payPerView.upcomingLiveMessage': 'Live event starts in:',
  'payPerView.purchaseSubtitle': 'Pay-Per-View (One-time payment)',
  'payPerView.completePurchase': 'COMPLETE PURCHASE',
  'payPerView.success': 'Pay-Per-View purchased successfully',
  'payPerView.purchased': 'PURCHASED',

  // Unlock Episodes
  'unlock.title': 'Unlock Episode:',
  'unlock.episode': 'Unlock Episode',
  'unlock.content.forever': "You'll have access to this content forever!",
  'unlock.episode.landing.header':
    'This is a premium episode. You can buy it and have access forever.',
  'unlock.membership.and.episode': 'Please subscribe to {name} and buy this episode to continue.',
  'unlock.checkout': 'Checkout',
  'unlock.episode.payment': 'One time payment',
  'unlock.episode.title': 'Episode {name}',
  'unlock.membership.and.episode.success': 'Membership and episode successfully purchased.',
  'unlock.episode.success': 'Episode successfully purchased.',

  // Add narratives
  'title.uploadNarrative': 'Upload',
  'form.title': 'Title',
  'form.titlePlaceholder': 'Title',
  'form.description': 'Description',
  'form.descriptionPlaceholder': 'Write a description',
  'form.uploadPostProduce': 'Upload Narrative',
  'form.narrative': 'Narrative',
  'form.narrative.submit': 'Share',
  'form.categories': 'Categories',
  'form.add': 'Add',
  'form.shareTo': 'Share to',
  'form.narratives': 'Narratives',

  // Add Originals
  'title.uploadOriginal': 'Create Series',
  'form.seriesTitlePlaceholder': 'Name your Series',
  'form.seriesDescriptionPlaceholder': 'Description of your Series',
  'form.logo': 'Logo',
  'form.uploadLogo': 'Upload logo',
  'form.trailer': 'Trailer',
  'form.uploadTrailer': 'Upload Trailer',
  'form.cover': 'Cover art (Desktop)',
  'form.uploadCover': 'Upload Cover Image',
  'form.coverMobile': 'Cover Art (Mobile)',
  'form.uploadCoverMobile': 'Upload Cover Image',
  'label.episodes': 'Episodes',
  'btn.addEpisode': 'Add episode',

  // Add Episode

  'title.nameYourEpisodePlaceholder': 'Name your Episode',
  'form.descriptionEpisodePlaceholder': 'Description of the episode',
  'form.creditStartTime': 'Credits Start Time (optional)',
  'form.earlyReleaseMembers': 'early release for members (optional)',
  'form.normalRelease': 'Normal release (optional)',
  'form.hhmmssPlaceholder': 'HH:MM:SS',
  'form.releaseDateTimeLabel': 'release date & time (optional)',
  'form.setPriceLabel': 'set Price',
  'form.date': 'Date',
  'form.time': 'Time',
  'form.nonMembers': 'non-Members',
  'form.members': 'Members',
  'form.newEpisode': 'New Episode',
  'form.video': 'video',
  'form.chooseVideo': 'choose Video',

  // DM
  'form.dmPlaceholder': 'Write your direct message',
  'form.shareBtn': 'Share',
  'form.dmMessage': 'This direct message will be shared with all the members subscribed to you',
  'form.dmTitle': 'Send DM',

  // Delete Modal
  'title.deleteAccount': 'Delete Account',

  // Delete Narrative
  'title.deleteNarrative': 'Delete Narrative',

  // Delete Originals
  'title.deleteOriginals': 'Delete Series'
};
