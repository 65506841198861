import { useEffect, useState, useMemo } from 'react';
import { timeToSeconds } from 'utils/date';

// TODO: Get credits time from backend
const DEFAULT_CREDIT_TIME = 10;

export default (player, creditsStartTime) => {
  const [duration, setDuration] = useState();
  const [timeRemaining, setTimeRemaining] = useState();
  const [progress, setProgress] = useState(0);

  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerCanceled, setBannerCanceled] = useState(false);

  const resetBanner = () => {
    setBannerVisible(false);
    setBannerCanceled(false);
  };

  const hideBanner = () => {
    setBannerVisible(false);
    setBannerCanceled(true);
  };

  const creditsTimeSeconds = useMemo(() => creditsStartTime && timeToSeconds(creditsStartTime), [
    creditsStartTime
  ]);

  useEffect(() => {
    player.on('meta', ({ duration }) => duration && setDuration(duration));
    player.on('time', ({ position }) => {
      setProgress(position / duration);
      const timeRemaining = duration - position;
      setTimeRemaining(timeRemaining);
      if (
        (creditsStartTime && position > creditsTimeSeconds) ||
        timeRemaining < DEFAULT_CREDIT_TIME
      ) {
        setBannerVisible(true);
      }
    });

    return () => {
      player.off('meta');
      player.off('time');
    };
  }, [player, duration]);

  return {
    duration,
    timeRemaining,
    progress,
    showBanner: bannerVisible && !bannerCanceled,
    resetBanner,
    hideBanner
  };
};
