/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import CustomCategory from 'components/CustomCategory/CustomCategory';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { useForm, useModal, useTextInputProps, useToast, useValidation } from 'hooks';
import useUploadSeries from 'hooks/series/useUploadSeries';
import { some } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import { useDispatch } from 'react-redux';
import { createSeriesValidation } from 'utils/constraints';
import Modal from 'components/common/Modal/Modal';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from 'components/icons/others/Close';
import LineDoubleIcon from 'components/icons/others/LineDouble';
import { useSelector } from 'react-redux';
import { LOADING } from '@rootstrap/redux-tools';
import { Line } from 'rc-progress';
import AddEpisodeForm from './AddEpisodeForm';

const fields = {
  title: 'title',
  description: 'description',
  logo_image: 'logo_image',
  desktop_image: 'desktop_image',
  mobile_image: 'mobile_image',
  categoryIds: 'categoryIds',
  trailer: 'trailer'
};

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.seriesDescriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.seriesTitlePlaceholder' },
  logo: { id: 'form.logo' },
  uploadLogo: { id: 'form.uploadLogo' },
  trailer: { id: 'form.trailer' },
  uploadTrailer: { id: 'form.uploadTrailer' },
  cover: { id: 'form.cover' },
  uploadCover: { id: 'form.uploadCover' },
  coverMobile: { id: 'form.coverMobile' },
  uploadCoverMobile: { id: 'form.uploadCoverMobile' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  episodes: { id: 'label.episodes' },
  addEpisode: { id: 'btn.addEpisode' },
  newEpisode: { id: 'form.newEpisode' },
  video: { id: 'form.video' },
  nameYourEpisodePlaceholder: { id: 'title.nameYourEpisodePlaceholder' },
  descriptionEpisodePlaceholder: { id: 'form.descriptionEpisodePlaceholder' },
  creditStartTime: { id: 'form.creditStartTime' },
  hhmmssPlaceholder: { id: 'form.hhmmssPlaceholder' },
  date: { id: 'form.date' },
  time: { id: 'form.time' },
  releaseDateTimeLabel: { id: 'form.releaseDateTimeLabel' },
  setPriceLabel: { id: 'form.setPriceLabel' },
  nonMembers: { id: 'form.nonMembers' },
  members: { id: 'form.members' },
  chooseVideo: { id: 'form.chooseVideo' },
  earlyReleaseMembers: { id: 'form.earlyReleaseMembers' },
  normalRelease: { id: 'form.normalRelease' }
});
const CreateSeriesForm = () => {
  const intl = useIntl();
  const { toggle, isShowing } = useModal();

  const validator = useValidation(createSeriesValidation);
  const logoRef = useRef(null);
  const trailerRef = useRef(null);
  const desktopCoverRef = useRef(null);
  const mobileCoverRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const { showErrorToast } = useToast();
  const [trailer, setTrailer] = useState(null);
  const [desktopCover, setDesktopCover] = useState(null);
  const [mobileCover, setMobileCover] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { episode } = useSelector(({ episodes: { episode } }) => ({ episode }));
  const {
    onSubmit,
    status,
    error,
    loading,
    uploadProgress,
    uploadedVideos,
    totalVideos,
    uploadingStatus
  } = useUploadSeries();

  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );
  const [episodes, setEpisodes] = useState([]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(episodes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEpisodes(items);
  }

  function removeCharacter(index) {
    const updatedCharacters = [...episodes];
    updatedCharacters.splice(index, 1);
    setEpisodes(updatedCharacters);
  }
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const handleLogoUpload = () => {
    logoRef.current.click();
  };

  const handleTrailerUpload = () => {
    trailerRef.current.click();
  };

  const handleDesktopCoverUpload = () => {
    desktopCoverRef.current.click();
  };

  const handleMobileCoverUpload = () => {
    mobileCoverRef.current.click();
  };

  const handleLogoChange = e => {
    const file = e.target.files[0];
    setLogo(file);
    handleValueChange(fields.logo_image, file);
  };

  const handleTrailerChange = e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setTrailer(file);
      handleValueChange(fields.trailer, file);
    } else {
      showErrorToast('Please upload a valid video file.');
      e.target.value = null;
    }
  };

  const handleDesktopCoverChange = e => {
    const file = e.target.files[0];
    setDesktopCover(file);
    handleValueChange(fields.desktop_image, file);
  };

  const handleMobileCoverChange = e => {
    const file = e.target.files[0];
    setMobileCover(file);
    handleValueChange(fields.mobile_image, file);
  };

  const handleCategoryChange = selectedOptions => {
    setSelectedCategories(selectedOptions);
    handleValueChange(fields.categoryIds, selectedOptions);
  };

  useEffect(() => {
    if (episode) {
      const newData = { ...episode, id: episodes.length + 1 };
      if (episodes.length === 0) {
        setEpisodes([newData]);
      } else {
        setEpisodes([...episodes, newData]);
      }
    }
  }, [episode]);

  return (
    <div className="series-form">
      <div className="series-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          label={intl.formatMessage(messages.description)}
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <input
          type="file"
          ref={logoRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="logo_image"
          onChange={handleLogoChange}
        />
        <input
          type="file"
          ref={trailerRef}
          style={{ display: 'none' }}
          accept="video/*"
          onChange={handleTrailerChange}
          name="trailer"
        />
        <input
          type="file"
          ref={desktopCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="desktop_image"
          onChange={handleDesktopCoverChange}
        />
        <input
          type="file"
          ref={mobileCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleMobileCoverChange}
          name="mobile_image"
        />
        <div className="upload-div">
          <span className={logo?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.logo)}
          </span>
          {logo ? (
            <div className="white-label">
              <span className="white-name">{logo?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setLogo(null)}
              >
                x
              </span>
            </div>
          ) : (
            <Button
              labelId={intl.formatMessage(messages.uploadLogo)}
              type="secondary"
              onClick={handleLogoUpload}
              className="profile-button"
            />
          )}
        </div>
        <div className="upload-div">
          <span className={trailer?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.trailer)}
          </span>
          {trailer ? (
            <div className="white-label">
              <span className="white-name">{trailer?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setTrailer(null)}
              >
                x
              </span>
            </div>
          ) : (
            <Button
              labelId={intl.formatMessage(messages.uploadTrailer)}
              type="secondary"
              onClick={handleTrailerUpload}
              className="profile-button"
            />
          )}
        </div>
        <div className="upload-div">
          <span className={desktopCover?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.cover)}
          </span>
          {desktopCover ? (
            <div className="white-label">
              <span className="white-name">{desktopCover?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopCover(null)}
              >
                x
              </span>
            </div>
          ) : (
            <Button
              labelId={intl.formatMessage(messages.uploadCover)}
              type="secondary"
              onClick={handleDesktopCoverUpload}
              className="profile-button"
            />
          )}
        </div>
        <div className="upload-div">
          <span className={mobileCover?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.coverMobile)}
          </span>
          {mobileCover ? (
            <div className="white-label">
              <span className="white-name">{mobileCover?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setMobileCover(null)}
              >
                x
              </span>
            </div>
          ) : (
            <Button
              labelId={intl.formatMessage(messages.uploadCoverMobile)}
              type="secondary"
              onClick={handleMobileCoverUpload}
              className="cover-button"
            />
          )}
        </div>
        <CustomCategory handleCategoryChange={handleCategoryChange} />
        <div className="creator-form-error p1">{error}</div>
        <div className="dnd-series-list">
          <h2>Episodes ({episodes.length})</h2>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="episodes">
              {provided => (
                <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                  {episodes.map(({ id, title }, index) => {
                    return (
                      <Draggable key={id} draggableId={id.toString()} index={index}>
                        {provided => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span>{index + 1}</span>
                            <div>
                              <p>
                                <LineDoubleIcon />
                                <span>{title}</span>
                              </p>
                              <button onClick={() => removeCharacter(index)}>
                                <CloseIcon color="#969696" />
                              </button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <div className="flex-buttons">
            <Button labelId="btn.addEpisode" className="submit-button" onClick={toggle} white />
            <Button
              labelId="form.narrative.submit"
              className="share-button"
              onClick={handleSubmit}
              disabled={
                status === Loading ||
                some(errors) ||
                !values[fields.title] ||
                !values[fields.description] ||
                !logo ||
                !trailer ||
                !desktopCover ||
                !mobileCover ||
                !values[fields.categoryIds] ||
                loading ||
                episodes.length === 0
              }
            >
              {(status === LOADING || loading) && (
                <div style={{ marginTop: '-15px' }}>
                  <Loading type="ball-clip-rotate" />
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
      <Modal hide={toggle} isShowing={isShowing} className="series-form-modal">
        <AddEpisodeForm handleClose={toggle} />
      </Modal>
      {(uploadProgress > 0 || uploadingStatus === 1) && (
        <div>
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            Finishing Upload {uploadedVideos}/{totalVideos}
          </div>
          <Line percent={uploadProgress} strokeWidth={1} strokeColor="#2400FC" />
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            {uploadProgress}% Complete
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CreateSeriesForm);
