import React, { useState } from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile, useToast } from 'hooks';
import { defineMessages, useIntl } from 'react-intl';
import Button from 'components/common/Button/Button';
import { func } from 'prop-types';
import useProfile from 'hooks/users/useProfile';
import { USER_TYPES } from 'hooks/useSession';
import celebritiesService from 'services/celebritiesService';
import UserService from 'services/userService';
import useLogout from 'hooks/users/useLogout';
import { useDispatch } from 'react-redux';

const messages = defineMessages({
  dmPlaceholder: { id: 'form.dmPlaceholder' },
  deleteTitle: { id: 'title.deleteAccount' },
  message: { id: 'form.dmMessage' }
});
const DeleteModal = ({ hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const { showToast, showErrorToast } = useToast();
  const { profile } = useProfile();
  const { logout } = useLogout();
  const [loading, setLoading] = useState(false);

  const onDeleteSuccess = () => {
    hide();
    setLoading(false);
    showToast('Delete Account successfully!');
    dispatch(logout());
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (profile.type === USER_TYPES.celebrity) {
        await celebritiesService.deleteCreatorAccount(profile.id);
        onDeleteSuccess();
      } else {
        await UserService.deleteUserAccount();
        onDeleteSuccess();
      }
    } catch ({ responses }) {
      setLoading(false);
      throw showErrorToast(responses);
    }
  };

  return (
    <div className="delete-modal" ref={ref}>
      <div className="delete-modal-header">
        {intl.formatMessage(messages.deleteTitle)}{' '}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer delete-modal-header-content">
        <div className="delete-content-div">
          <span>Are you sure you want to delete your account ?</span>
          <div className="buttons">
            <Button className="cancel-btn" onClick={hide}>
              Cancel
            </Button>
            <Button
              type="primary"
              labelId="Yes, delete"
              className="delete-btn"
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  hide: func.isRequired
};

export default DeleteModal;
