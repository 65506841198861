import React, { useEffect, useRef } from 'react';

import Hls from 'hls.js';
import { bool, string } from 'prop-types';

const VideoQuickPlayer = ({ videoUrl, autoPlay = true }) => {
  const videoRef = useRef(null);

  const isEncodedPlaylistFile = videoUrl?.toLowerCase()?.endsWith('.m3u8');

  useEffect(() => {
    const video = videoRef.current;

    if (isEncodedPlaylistFile) {
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoUrl;
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(video);
      }
    }
  }, [isEncodedPlaylistFile, videoUrl]);

  if (isEncodedPlaylistFile) {
    return <video ref={videoRef} width="100%" height="100%" autoPlay={autoPlay} loop muted />;
  }
  return (
    <video width="100%" height="100%" autoPlay={autoPlay} loop muted>
      <source src={videoUrl} type="video/mp4" />
    </video>
  );
};

VideoQuickPlayer.propTypes = {
  videoUrl: string.isRequired,
  autoPlay: bool
};

export default VideoQuickPlayer;
