import React, { useEffect } from 'react';
import { shape, bool, string, func } from 'prop-types';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { narrativeShape, celebrityInfoShape } from 'constants/propTypesShapes';
import { getPlayerUrl, checkSubscription, getFullName } from 'utils/helpers';
import useProfile from 'hooks/users/useProfile';

import usePlayer from 'hooks/player/usePlayer';
import NarrativesControls from 'components/Players/Controls/NarrativesControls';
import { ArrowIcon } from 'components/icons';

import useNarrativeControls from 'components/narratives/useNarrativeControls';

import { black40 } from 'styles/common/_constants.scss';
import { useAnalytics, useDisappearingControls } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';
import ReactPlayer from 'react-player';

const NarrativesPlayer = ({
  narrative: { link, title, description },
  className,
  play,
  indexId,
  celebrity,
  showAds,
  goToNext,
  goToPrevious,
  showPreviousArrow,
  showNextArrow
}) => {
  const {
    profile: { memberships }
  } = useProfile();
  const { trackEvent } = useAnalytics();

  const isSubscribed = checkSubscription(memberships, celebrity.id);
  const { handleMouseMove, controlsVisible, handleClick } = useDisappearingControls(true);

  const playerUrl =
    showAds && !isSubscribed
      ? getPlayerUrl(process.env.PLAYER_WITH_ADS_ID)
      : getPlayerUrl(process.env.PLAYER_WITHOUT_ADS_ID);

  const { player } = usePlayer({
    playerDiv: indexId,
    file: link,
    playerUrl,
    controlsVisible,
    config: {
      aspectratio: '9:16'
    },
    mute: true
  });

  const trackTiming = () => {
    const { firstName, lastName } = celebrity;
    player &&
      trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
        Seconds: Math.round(player.getPosition()),
        'Celebrity Name': getFullName(firstName, lastName),
        'Narrative Name': title
      });
  };

  const goToNextNarrative = () => {
    if (goToNext) {
      trackTiming();
      goToNext();
    }
  };

  const goToPreviousNarrative = () => {
    if (goToPrevious) {
      trackTiming();
      goToPrevious();
    }
  };

  useEffect(() => {
    if (!play) {
      trackTiming();
    }
  }, [play]);

  useNarrativeControls(goToNextNarrative, goToPreviousNarrative, showNextArrow);

  useEffect(() => {
    if (player && play && player.getState() !== 'idle') player.play();
    if (player && !play) player.pause();
  }, [play, player]);

  return (
    <div
      className={cn(
        'video-container video-container-narratives visible',
        { 'no-pointer': isMobile },
        className
      )}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <ReactPlayer
        playsinline
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={link}
      />
      {/* <div id={indexId} /> */}
      {player && play && (
        <>
          <NarrativesControls
            title={title}
            description={description}
            player={player}
            celebrity={celebrity}
            goToNext={goToNextNarrative}
            controlsVisible={controlsVisible}
            mute
          />
          {!!goToNext && !!goToPrevious && (
            <div className="navigation-buttons desktop-only">
              <div className="nav-button previous-button" onClick={goToPreviousNarrative}>
                {showPreviousArrow && (
                  <ArrowIcon width={20} height={10} type="left" color={black40} />
                )}
              </div>
              <div className="nav-button next-button" onClick={goToNextNarrative}>
                {showNextArrow && <ArrowIcon width={20} height={10} type="right" color={black40} />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

NarrativesPlayer.propTypes = {
  narrative: shape(narrativeShape).isRequired,
  className: string,
  play: bool,
  indexId: string.isRequired,
  celebrity: shape(celebrityInfoShape),
  showAds: bool,
  goToNext: func,
  goToPrevious: func,
  showPreviousArrow: bool,
  showNextArrow: bool
};

export default NarrativesPlayer;
