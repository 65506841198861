import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ERROR, LOADING } from '@rootstrap/redux-tools';
import cn from 'classnames';
import { bool, func } from 'prop-types';

import { renderImage } from 'utils/helpers';
import useVerificationCode from 'hooks/users/useVerificationCode';
import { useSession } from 'hooks';

import Button from 'components/common/Button/Button';
import Loading from 'components/common/Loading';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import CodeInput from 'components/common/CodeInput/CodeInput';

import verifyPhoneBackground from 'assets/verifyphone-bkg.jpg';
import verifyPhoneBackgroundMobile from 'assets/verifyphone-bkg-mobile.png';

const CODE_DIGITS = 5;

const VerifyPhone = ({ isSignUp, isModal, onVerifySuccess }) => {
  const intl = useIntl();
  const { userToConfirm } = useSession();
  const [code, setCode] = useState();
  const { onSubmit, status: verifyStatus, resendCode } = useVerificationCode(
    isSignUp,
    onVerifySuccess
  );

  const { phone } = userToConfirm;

  const error = verifyStatus === ERROR;
  const correctCode = code?.length === CODE_DIGITS;
  const loading = verifyStatus === LOADING;

  return (
    <div className={cn('verify-phone', { 'verify-phone-modal': isModal })}>
      <BackgroundOpacity
        background={renderImage(verifyPhoneBackground, verifyPhoneBackgroundMobile)}
        opacity={0.5}
      />
      <div className="verify-phone-gradient" />
      <div className="layer content-container">
        <div className="verify-phone-container">
          <div className="verify-phone-content">
            <div className="container">
              <h1>{intl.formatMessage({ id: 'verifyPhone.title' })}</h1>
              <div className="p2 subtitle">
                {intl.formatMessage({ id: 'verifyPhone.subtitle' })}
              </div>
              <div className="tag-bold code-label">
                {intl.formatMessage({ id: 'verifyPhone.enterCode' })}
              </div>
              <CodeInput onChange={code => setCode(code)} fields={CODE_DIGITS} error={error} />
            </div>
            <div className="container bottom-container">
              {error && (
                <div className="error p2">
                  {intl.formatMessage({ id: 'verifyPhone.code.error' })}
                </div>
              )}
              <h4 className="user-phone">{phone}</h4>
              <Button
                labelId="verifyPhone.submit"
                className="submit-button"
                disabled={!correctCode || loading}
                onClick={() => onSubmit({ phone: userToConfirm.phone, confirmationToken: code })}
              />
              {loading && <Loading type="ball-clip-rotate" />}
              <div onClick={() => resendCode(phone)}>
                <h5 className="resend-verification bold">
                  {intl.formatMessage({ id: 'verifyPhone.resendCode' })}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VerifyPhone.propTypes = {
  isSignUp: bool,
  isModal: bool,
  onVerifySuccess: func
};

export default VerifyPhone;
