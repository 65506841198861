/* eslint-disable react/prop-types */
import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import VideoImg from 'assets/icons/Video/Video.png';
import ClockImg from 'assets/icons/Clock/clock.png';
import { useClickOutside, useIsMobile } from 'hooks';

const GoLiveModal = ({ hide, id }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        Live Options <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <ul>
          <li>
            <div className="d-flex">
              <div className="icon">
                <img src={VideoImg} alt="videoImg" width={20} height={20} />
              </div>
              Go Live now
            </div>
          </li>
          <li>
            <div className="d-flex">
              <div>
                <div className="icon">
                  <img src={ClockImg} alt="clockImg" width={20} height={20} />
                </div>
                Schedule live event
              </div>
              <button type="button">coming soon</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GoLiveModal;
