export const PRIVACY_POLICY = [
  {
    body:
      `IDENTIFI INC. PRIVACY & DATA POLICY – COMMITMENT TO YOUR PRIVACY \n This site is owned and operated by Identifi, Inc. (“Identifi”). Your privacy on the Internet ` +
      `is of the utmost importance to us.  At Identifi, Inc., we want to make your experience online satisfying and safe. \n \n Because we gather certain types of information ` +
      ` about you as users who visit our website (www.weidentifi.com) and any of our related services, content or applications (the Site and these services, content and ` +
      ` applications together will be described as the "Service"), we feel you should fully understand our policy and the terms and conditions surrounding the capture and ` +
      ` use of that information. This privacy statement discloses what information we gather and how we use it.`
  },
  {
    title: 'INFORMATION IDENTIFI, INC. GATHERS AND TRACKS',
    body:
      `Identifi, Inc. gathers information about users of our Service: \n \n ` +
      `• Information that users provide through optional, voluntary submissions. These are voluntary submissions to receive our electronic newsletters, to participate in our ` +
      ` message boards or chat forums, to email a friend, and from participation in direct messaging, polls and surveys \n \n ` +
      `• Information Identifi, Inc. gathers through aggregated tracking information derived mainly by tallying views of pages, videos and images throughout our sites. This information ` +
      `allows us to better tailor our content to user needs and to help our advertisers and sponsors better understand the demographics of our audience. Under no circumstances does Identifi, ` +
      `Inc. divulge any information about an individual user to a third party. \n \n ` +
      `• When you log into Identifi by logging in to the Services through a third party social media service, such as Facebook, Instagram, Twitter or Snapchat you are giving us permission to ` +
      ` access, store and use any information that you permit the applicable third party social media service to share with us in accordance with the privacy policy of that specific company’s ` +
      `service and the privacy settings that are applicable to your account on that company’s service. We encourage you to review, and if necessary, adjust, your privacy settings on the applicable ` +
      ` social media service before logging in to the Identifi Services through a social media service. Information that we receive from your third party social media service is stored and used ` +
      `by Identifi in accordance with this Privacy Policy.`
  },
  {
    title: `Identifi Gathers User Information In The Following Processes:`
  },
  {
    title: 'Optional Voluntary Information',
    body:
      `We offer the following free services, which require some type of voluntary submission of personal information by users: \n \n ` +
      `1. Electronic newsletters policy (Dispatches) \n We will offer a free electronic newsletter to users. Identifi, Inc. gathers the email addresses of users who voluntarily subscribe. Users may ` +
      `remove themselves from this mailing list by following the link provided in every newsletter that points users to the subscription management page. Users can also subscribe to the newsletters at the time of registration. \n \n ` +
      `2. Message and chat boards/forums policy \n Users of the site’s Message Boards, Live Chat features and Forums must register separately for these services in order to post messages, although ` +
      `they are not required to register to visit the site. During registration the user is required to supply a name, password, and phone number or email address. \n \n` +
      `3. Polling \n We may offer interactive polls to users so they can easily share their opinions with other users and see what our audience thinks about important issues. Opinions or other responses to polls are aggregated and are ` +
      `not identifiable to any particular user. Identifi, Inc. may use a system to “tag” users after they have voted, so they can vote only once on a particular question. This tag is not correlated with information about individual users. \n \n ` +
      `4. Surveys \n Identifi, Inc. may occasionally conduct user surveys to better target our content to our audience. We sometimes share the aggregated demographic information in these surveys with our sponsors, advertisers and partners. We ` +
      ` never share any of this information about specific individuals with any third party. \n \n ` +
      `5. Usage tracking \n Identifi, Inc. tracks user traffic patterns throughout all of our sites. However, we do not correlate this information with data about individual users. Identifi, Inc. does break down overall usage statistics according ` +
      `to a user’s domain name, browser type, and MIME type by reading this information from the browser string (information contained in every user’s browser). \n \n` +
      `Identifi, Inc. may track and catalog the search terms users enter in our Search function, but this tracking is never associated with individual users. We use tracking information to determine which areas of our sites users like and don’t ` +
      ` like based on traffic to those areas. We do not track what individual users read, but rather how well each page performs overall. This helps us continue to build a better service for you.`
  },
  {
    title: 'Cookies',
    body:
      'We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. \n \n We may place a text file called a “cookie” in the browser files of your computer. The cookie itself does ' +
      'not contain Personal Information although it will enable us to relate your use of this site to information that you have specifically and knowingly provided. But the only personal information a cookie can contain is information you supply ' +
      'yourself. A cookie can’t read data off your hard disk or read cookie files created by other sites. Identifi, Inc. uses cookies to track user traffic patterns (as described above).  We may use data we collect automatically to recognize you ' +
      'as a return visitor, to serve relevant online advertising to you or to analyze trends that will improve your experience of the Identifi Services. \n \n You can refuse cookies by turning them off in your browser. If you’ve set your browser to warn ' +
      'you before accepting cookies, you should receive the warning message with each cookie. You do not need to have cookies turned on to use this site. However, you do need cookies to participate actively in message boards, forums, polling and ' +
      'surveys. If you set your browser to limit or remove cookies or otherwise block our use of cookies, some features of the Services may be unavailable or unable to function properly. In addition, if you access the Services through a mobile ' +
      'device, we may also be able to identify the location of your mobile device. You may choose not to share your location details with us by adjusting your mobile device’s location services settings. If you require assistance in turning off ' +
      'cookies, please contact either your service provider or device manufacturer directly.'
  },
  {
    title: 'USE OF INFORMATION',
    body:
      `Do we disclose any information to outside parties? \n \n We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties and network partners who assist ` +
      `us in operating our Service, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, ` +
      `enforce our Service policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`
  },
  {
    title: 'California Online Privacy Protection Act Compliance',
    body:
      `Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not` +
      `distribute your personal information to outside parties without your consent.`
  },
  {
    title: 'Childrens Online Privacy Protection Act Compliance',
    body:
      `We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. ` +
      `Our website, products and services are all directed to people who are at least 13 years old or older. The Services may include unmonitored content posted by users ` +
      ` or sourced from other third parties and such content may be unsuitable for children. We do not knowingly collect or distribute information from or about children under age 13. \n \n \n`
  },
  {
    body:
      `Identifi, Inc. uses any information voluntarily given by our users to enhance their experience in our site, whether to provide interactive or personalized elements on the sites or to better prepare future content based on the interests of our users. \n \n ` +
      `As stated above, we use information that users voluntarily provide in order to send out electronic newsletters and to enable users to participate in polls, surveys, chats,message boards, and forums. We send out newsletters to subscribers, notifications, ` +
      `updates, alerts, and occasionally send out special editions when we think subscribers might be particularly interested in something we are doing, an event or new content. Identifi, Inc. never shares newsletter or subscriber mailing lists with any ` +
      `third parties, including advertisers, sponsors or partners. \n \n When we use tracking information to determine which areas of our sites users like and don’t like based on traffic to those areas. We do not track what individual users read, but rather  ` +
      `how well each page performs overall. This helps us continue to build a better service for you. We track search terms entered in Search function as one of many measures of what interests our users. But we don’t track which terms a particular user enters. \n \n ` +
      `Identifi, Inc. may create aggregate reports on user demographics and traffic patterns for advertisers, sponsors and partners. This allows our advertisers to advertise more effectively, and allows our users to receive advertisements that are pertinent to their needs.`
  },
  {
    title: 'SHARING OF THE INFORMATION',
    body:
      `Identifi, Inc. uses the above-described information to tailor our content to suit your needs and help our advertisers better understand our audience’s demographics. This is essential to keeping our service free. We will not share information about ` +
      `individual users with any third party, except to comply with applicable law or valid legal process or to protect the personal safety of our users or the public.`
  },
  {
    title: 'SECURITY',
    body:
      `Identifi, Inc. operates secure data networks protected by industry standard firewall and password protection systems. Our security and privacy policies are periodically reviewed and enhanced as necessary and only authorized individuals have access to ` +
      ` the information provided by our users. Identifi cannot guarantee or warrant the security of any information you transmit on or through the Service and you do so at your own risk.`
  },
  {
    title: 'OPT-OUT POLICY',
    body:
      `We give users options wherever necessary and practical. Such choices include: \n \n ` +
      `• Opting not to register to receive our emails and other alerts and electronic notifications. \n \n` +
      `• Opting not to participate in certain interactive areas, which completely alleviates the need to gather any personally identifiable information from our users.`
  },
  {
    title: 'YOUR CONSENT',
    body:
      `By using this site and/or the Service, you consent to the collection and use of this information by Identifi, Inc.. If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information ` +
      ` we collect, how we use it, and under what circumstances we disclose it.`
  },
  {
    title: 'Google Ads',
    googleAd: true,
    body:
      `• Third party vendors, including Google, use cookies to serve ads based on a users’ prior visits to the Service \n \n • Google's use of the DoubleClick cookie  ` +
      `enables it and its partners to serve ads to your users based on each user’s visit to the Identifi site and/or Service. \n \n • Users may opt out of the use of ` +
      `the DoubleClick cookie for interest-based advertising by visiting `,
    body2: `. You may also opt out of a third-party vendor's use of cookies for interest-based advertising by visiting `
  },
  {
    title: 'Contacting Us',
    body:
      'If there are any questions regarding this privacy policy you may contact us using the information below. \n \n '
  }
];

export const LEGEND =
  ' \n The weidentifi.com and related digital properties are operated and published by identifi, Inc.';

export const ADS_SETTINGS_LINK = 'https://www.google.com/settings/ads';
export const ADS_SETTINGS_TEXT = 'Ad Settings';

export const ABOUT_ADS_LINK = 'https://youradchoices.com';
export const ABOUT_ADS_TEXT = 'aboutads.info';
