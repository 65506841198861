import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import Header from 'components/header/Header/Header';
import SerieInfo from 'components/series/SerieInfo/SerieInfo';
import UnlockEpisodeButton from 'components/unlock/UnlockEpisodeButton';
import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { routesPaths } from 'constants/routesPaths';
import { PURCHASE_TYPES } from 'constants/constants';
import { serieShape, unlockContentConfigShape } from 'constants/propTypesShapes';

import useEpisode from 'hooks/useEpisode';
import useOneSeries from 'hooks/useOneSeries';
import { useIsMobile, useNavigation } from 'hooks';
import useMembership from 'hooks/celebrities/useMembership';

import { renderImage, routeWithProps } from 'utils/helpers';

const PROFILE_IMAGE_SIZE = 140;

const UnlockEpisodeLanding = ({ serie, episode, config }) => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const { goTo } = useNavigation();
  const { setEpisode, setFlowConfig, episode: ep } = useEpisode();
  const { getSerie } = useOneSeries();
  const { getMembership } = useMembership();

  const { desktopImageUrl, mobileImageUrl, celebrity, episodes } = serie;
  const { profileImageUrl, username } = celebrity;

  useEffect(() => {
    const CURRENT_EPISODE = episodes?.find(ep => ep.id == episode);
    if (CURRENT_EPISODE) setEpisode(CURRENT_EPISODE);
    if (config) setFlowConfig(config);
    if (config.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE) {
      getMembership(celebrity.id);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="unlock-episode">
        <BackgroundOpacity
          background={renderImage(desktopImageUrl, mobileImageUrl)}
          opacity={0.5}
        />
        <div className="unlock-episode-gradient" />
        <div className="unlock-episode-content">
          <div className="left-content content-item">
            <ProfilePicture
              profileImage={
                config.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE
                  ? profileImageUrl
                  : ep?.thumbnailsUrls?.[0]?.src
              }
              width={PROFILE_IMAGE_SIZE}
              height={PROFILE_IMAGE_SIZE}
            />
            {config.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE && <h2>{config.title}</h2>}
            {config.purchaseType === PURCHASE_TYPES.EPISODE && (
              <>
                <h2 className="bold episode-title">{config.title}</h2>
                <h2 className="padding-top-episode-name">{config.episodeName}</h2>
              </>
            )}
            <div className="button-container">
              <UnlockEpisodeButton
                celebrity={celebrity}
                title={config.buttonLabel}
                size={isMobile ? 'large' : 'small'}
                onSuccess={() => getSerie(id)}
              />
              <Button
                labelId="earlyRelease.watchAnother"
                type="secondary"
                size={isMobile ? 'large' : 'small'}
                onClick={() =>
                  goTo(
                    routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() })
                  )
                }
              />
            </div>
          </div>
          <SerieInfo serie={serie} hideDescription className="content-item" />
        </div>
      </div>
    </>
  );
};

UnlockEpisodeLanding.propTypes = {
  serie: shape(serieShape).isRequired,
  episode: string.isRequired,
  config: shape(unlockContentConfigShape).isRequired
};

export default UnlockEpisodeLanding;
