import React from 'react';
import { shape } from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { categoryFeaturedShape } from 'constants/propTypesShapes';
import { routeWithProps, getFullName } from 'utils/helpers';
import { useAnalytics, useModal, useNavigation } from 'hooks';

import FeaturedThumbnail from 'components/categoryList/FeaturedThumbnail/FeaturedThumbnail';
import SimpleThumbnail from 'components/categoryList/SimpleThumbnail/SimpleThumbnail';
import SerieLanding from 'components/serieLanding/SerieLanding/SerieLanding';
import Modal from 'components/common/Modal/Modal';

const CategorySection = ({ category: { id, slug, name, seriesOriginal, narratives } }) => {
  const intl = useIntl();
  const { goTo, getLinkTo } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { toggle, isShowing } = useModal();

  const onSimpleThumbnailClick = narrative => {
    const {
      id,
      title,
      celebrity: { id: celebrityId, firstName, lastName }
    } = narrative;
    trackEvent(MIXPANEL_EVENTS.homeHighlight, {
      category: name,
      title,
      creatorName: getFullName(firstName, lastName)
    });
    const route = routeWithProps(routesPaths.narratives, { celebrityId, id });
    goTo(route);
  };

  return (
    <div className="category-section">
      <div className="flex-spaced category-section-heading">
        <h3>{name}</h3>
        <Link to={getLinkTo(routeWithProps(routesPaths.category, { id, slug }))}>
          <h6 className="semibold link">{intl.formatMessage({ id: 'general.explore' })}</h6>
        </Link>
      </div>
      <div className="category-section-content">
        <FeaturedThumbnail
          serie={seriesOriginal}
          className="category-block"
          categoryName={name}
          onClick={toggle}
        />
        <div className="narratives-block category-block">
          {narratives.map(narrative => (
            <SimpleThumbnail
              narrative={narrative}
              key={narrative.id}
              categoryName={name}
              onClick={() => onSimpleThumbnailClick(narrative)}
            />
          ))}
        </div>
      </div>
      <Modal hide={toggle} isShowing={isShowing}>
        <SerieLanding serie={seriesOriginal} />
      </Modal>
    </div>
  );
};

CategorySection.propTypes = {
  category: shape(categoryFeaturedShape)
};

export default CategorySection;
