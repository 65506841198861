/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { ArrowIcon } from 'components/icons';
import React, { useRef, useState } from 'react';

function Accordion(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState('0px');

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  }

  return (
    <div className="accordion__item">
      <div className={`accordion ${active ? 'active' : ''}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
        <span style={{ marginLeft: '20px' }}>
          <ArrowIcon />
        </span>
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        {props.children}
      </div>
    </div>
  );
}

export default Accordion;
