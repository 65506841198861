import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Select from 'react-select';
import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';
import { func } from 'prop-types';

const messages = defineMessages({
  categories: { id: 'form.categories' }
});

const CustomCategory = ({ handleCategoryChange }) => {
  const intl = useIntl();
  const [categories, setCategories] = useState();
  const getCategories = async () => {
    try {
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);
    } catch ({ response }) {
      throw parseError(response);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className="categories">
      <span className="custom-label">{intl.formatMessage(messages.categories)}</span>
      <div className="custom-select">
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={categories}
          onChange={handleCategoryChange}
        />
      </div>
    </div>
  );
};

CustomCategory.propTypes = {
  handleCategoryChange: func
};

export default CustomCategory;
