import { createThunk } from '@rootstrap/redux-tools';

import membershipService from 'services/membershipService';
import parseError from 'utils/parseError';

export const createMembership = createThunk(
  'CREATE_MEMBERSHIP',
  async (celebrityId, membershipId, creditCardId) => {
    try {
      const membership = await membershipService.createMembership(
        celebrityId,
        membershipId,
        creditCardId
      );
      return membership;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const cancelMembership = createThunk(
  'CANCEL_MEMBERSHIP',
  async (celebrityId, membershipId) => {
    try {
      await membershipService.cancelMembership(celebrityId, membershipId);
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const buyEpisode = createThunk('BUY_EPISODE', async params => {
  try {
    await membershipService.buyEpisode(params);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const subscribeAndBuyEpisode = createThunk(
  'SUBSCRIBE_AND_BUY_EPISODE',
  async (celebrityId, membershipPlanId, creditCardId, episodeId) => {
    try {
      const membership = await membershipService.createMembership(
        celebrityId,
        membershipPlanId,
        creditCardId
      );
      await membershipService.buyEpisode({ episodeId, creditCardId });
      return membership;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);
