import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { func } from 'prop-types';
import cn from 'classnames';

import { routesPaths } from 'constants/routesPaths';

import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import { routeWithProps } from 'utils/helpers';
import { legalTabs } from 'constants/constants';

const routes = [
  {
    label: { id: 'navbar.home' },
    route: routesPaths.home
  },
  {
    label: { id: 'navbar.linkOfInterest' },
    route: routeWithProps(routesPaths.legal, { tab: legalTabs.LINK_OF_INTEREST.tab })
  },
  {
    label: { id: 'navbar.privacyTerms' },
    route: routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab })
  },
  {
    label: { id: 'navbar.termsOfService' },
    route: routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab })
  }
];

const MenuModal = ({ hide }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return (
    <div className="menu-modal">
      <ModalHeader showLogo hideModal={hide} />
      <div>
        {routes.map(({ route, label }) => (
          <div key={route} className={cn('menu-modal-item', { selected: route === pathname })}>
            <Link to={route} onClick={hide}>
              <h5>{intl.formatMessage(label)}</h5>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

MenuModal.propTypes = {
  hide: func
};

export default MenuModal;
