import React, { useState, useEffect, useRef } from 'react';
import { shape, bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import cn from 'classnames';

import { useAnalytics, useCelebrity, useIsMobile, useModal, useNavigation } from 'hooks';
import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { routeWithProps } from 'utils/helpers';

import SerieBannerDesktop from 'components/series/SerieBanner/SerieBannerDesktop';
import SerieBannerMobile from 'components/series/SerieBanner/SerieBannerMobile';
import EpisodesGrid from 'components/episodes/EpisodesGrid/EpisodesGrid';
import { ArrowIcon } from 'components/icons';

import { black40 } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import TrailerModal from 'components/featured/TrailerModal/TrailerModal';
import SerieLanding from 'components/serieLanding/SerieLanding/SerieLanding';

const SerieBlock = ({ serie, playVideo, isLoggedUser }) => {
  const intl = useIntl();
  const { goTo } = useNavigation();
  const [showEpisodes, setShowEpisodes] = useState(false);
  const { trackEvent } = useAnalytics();
  const isMobile = useIsMobile();
  const scrollRef = useRef(null);
  const { celebrity } = useCelebrity();
  const { refresh } = useCelebrity(celebrity.id);
  const { toggle: toggleTrailer, isShowing: isShowingTrailer } = useModal(false);
  const { toggle: toggleLanding, isShowing: isShowingLanding } = useModal(false);

  const { search } = useLocation();
  const { series } = queryString.parse(search);

  const { title, description, bannerUrl } = serie;

  useEffect(() => {
    if (series == serie.id) {
      window.scrollTo({ left: 0, top: scrollRef.current.offsetTop, behavior: 'smooth' });
    }
  }, [series]);

  const toggleShowEpisodes = () => {
    setShowEpisodes(!showEpisodes);
    trackEvent(MIXPANEL_EVENTS.showEpisodes, { 'Series name': serie.title });
  };

  const onClickPlay = () => {
    trackEvent(MIXPANEL_EVENTS.playFirstEpisode, { 'Series name': serie.title });
    goTo(
      routeWithProps(routesPaths.series, {
        id: serie.id,
        episode: serie.episodes[0]?.id
      })
    );
  };

  return (
    <div
      className={cn('serie-block', { separator: !showEpisodes }, { firstSerie: playVideo })}
      ref={scrollRef}
    >
      {isMobile ? (
        <SerieBannerMobile
          serie={serie}
          showEpisodes={showEpisodes}
          toggleShowEpisodes={toggleShowEpisodes}
          onClickPlay={onClickPlay}
          toggleTrailer={toggleTrailer}
          toggleLanding={toggleLanding}
          withBorder={!serie.bannerUrl}
          celebrityId={celebrity.id}
          refresh={refresh}
          isLoggedUser={isLoggedUser}
        />
      ) : (
        <SerieBannerDesktop
          serie={serie}
          showEpisodes={showEpisodes}
          toggleShowEpisodes={toggleShowEpisodes}
          onClickPlay={onClickPlay}
          playVideo={playVideo}
          toggleTrailer={toggleTrailer}
          toggleLanding={toggleLanding}
          withBorder={!serie.bannerUrl}
          celebrityId={celebrity.id}
          refresh={refresh}
          isLoggedUser={isLoggedUser}
        />
      )}
      <Modal hide={toggleTrailer} isShowing={isShowingTrailer} disablePadding>
        <TrailerModal title={title} description={description} videoUrl={bannerUrl} />
      </Modal>
      <Modal hide={toggleLanding} isShowing={isShowingLanding}>
        <SerieLanding serie={serie} />
      </Modal>
      {showEpisodes && (
        <>
          <div className="episode-grid-container">
            <EpisodesGrid episodes={serie.episodes} serieId={serie.id} className="max-width" />
          </div>
          <div className="close-episodes" onClick={toggleShowEpisodes}>
            <ArrowIcon type="up" color={black40} />
            <h5 className="bold">{intl.formatMessage({ id: 'general.close' })}</h5>
          </div>
        </>
      )}
    </div>
  );
};

SerieBlock.propTypes = {
  serie: shape(serieShape),
  playVideo: bool,
  isLoggedUser: bool
};

export default SerieBlock;
