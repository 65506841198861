/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/media-has-caption */
import { LOADING, SUCCESS, useStatus } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import {
  useForm,
  useModal,
  useNavigation,
  useTextInputProps,
  useToast,
  useValidation
} from 'hooks';
import { createNarrativeValidation } from 'utils/constraints';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Check from 'assets/icons/Check/Check.png';
import Select from 'react-select';
import useUploadingNarrative from 'hooks/narrative/useUploadingNarrative';
import { createNarrative, processNarrative } from 'state/actions/narrativeActions';
import { useDispatch } from 'react-redux';
import { some } from 'lodash';
import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import Modal from 'components/common/Modal/Modal';
import { Line } from 'rc-progress';
import PublishModal from './PublishModal';

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.descriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.titlePlaceholder' },
  postProduce: { id: 'form.narrative' },
  uploadPostProduce: { id: 'form.uploadPostProduce' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  narratives: { id: 'form.narratives' }
});

const fields = {
  title: 'title',
  description: 'description',
  narrative: 'narrative',
  category_ids: 'category_ids'
};

const AddNarrativeForm = () => {
  const intl = useIntl();
  const { toggle, isShowing } = useModal();
  const { goToWithSearch } = useNavigation();
  const dispatch = useDispatch();
  const narrativeRef = useRef(null);
  const [narrative, setNarrative] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState();
  const celebrityId = useRef();
  const username = useRef();
  const validator = useValidation(createNarrativeValidation);
  const { loading, uploadingNarrative } = useUploadingNarrative();
  const { error, status } = useStatus(createNarrative);
  const [processLoading, setProcessLoading] = useState(false);
  const [postProduceValue, setPostProductValue] = useState(0);
  const [narrativeData, setNarrativeData] = useState();
  const { showErrorToast } = useToast();
  const [uploadProgress, setUploadProgress] = useState(0);

  const onSubmit = useCallback(
    narrative => {
      toggle();
      setNarrativeData(narrative);
    },
    [dispatch, selectedCategories, postProduceValue]
  );

  useEffect(() => {
    if (postProduceValue !== 0) {
      const categoryIds = selectedCategories.map(({ value }) => value);
      narrativeData.postProduce = postProduceValue === 1;
      narrativeData.category_ids = categoryIds;
      dispatch(createNarrative(celebrityId, narrativeData));
    }
  }, [postProduceValue]);

  const handlePublish = value => {
    value ? setPostProductValue(1) : setPostProductValue(2);
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const handleNarrativeUpload = () => {
    narrativeRef.current.click();
  };
  const handleNarrativeChange = e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setNarrative(file);
      handleValueChange(fields.narrative, file);
    } else {
      showErrorToast('Please upload a valid video file.');
      e.target.value = null;
    }
  };

  const handleCategoryChange = selectedOptions => {
    setSelectedCategories(selectedOptions);
    handleValueChange(fields.category_ids, selectedOptions);
  };

  const getCategories = async () => {
    try {
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
      celebrityId.current = data.user.id;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getCategories();
    getProfile();
  }, []);

  const onCompleteVideoUpload = () => {
    dispatch(processNarrative(celebrityId, uploadingNarrative.id, narrative));
  };

  const { status: processNarrativeStatus } = useStatus(processNarrative);

  useEffect(() => {
    const processNarrativeAsync = async () => {
      if (processNarrativeStatus === SUCCESS) {
        await dispatch(processNarrative.reset());
        setProcessLoading(false);
        const route = routeWithProps(routesPaths.celebrityId, {
          username: username.current.toLowerCase()
        });
        goToWithSearch(route, `?tab=2`);
      }
    };
    processNarrativeAsync();
  }, [dispatch, celebrityId, processNarrativeStatus, goToWithSearch]);

  const uploadVideosInJWPlayer = async videos => {
    try {
      await Promise.all(
        videos.map(async video => {
          try {
            setUploadProgress(0);
            const formData = new FormData();
            formData.append('file', video.narrative);
            formData.append('mediaId', video.mediaId);
            const totalSize = video.narrative.size;
            let uploadedSize = 0;
            await new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();
              xhr.open('POST', video.uploadUrl);
              xhr.upload.onprogress = event => {
                if (event.lengthComputable) {
                  uploadedSize = event.loaded;
                  const progress = Math.round((uploadedSize / totalSize) * 100);
                  setUploadProgress(progress);
                }
              };
              xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                  resolve(xhr.response);
                } else {
                  reject(new Error('Upload failed'));
                }
              };
              xhr.onerror = () => reject(new Error('Upload failed'));
              xhr.send(formData);
            });
            onCompleteVideoUpload && onCompleteVideoUpload();
          } catch (error) {
            showErrorToast('Something went wrong, try again please!');
          }
        })
      );
    } catch {
      showErrorToast('Something went wrong, try again please!');
    }
  };

  useEffect(() => {
    if (status === SUCCESS && uploadingNarrative?.uploadUrl) {
      dispatch(createNarrative.reset());
      setProcessLoading(true);
      const videos = [
        {
          narrative,
          mediaId: uploadingNarrative.mediaId,
          uploadUrl: uploadingNarrative.uploadUrl
        }
      ];
      uploadVideosInJWPlayer(videos);
    }
  }, [dispatch, status, uploadingNarrative?.uploadUrl]);

  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          label={intl.formatMessage(messages.description)}
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <input
          type="file"
          ref={narrativeRef}
          style={{ display: 'none' }}
          accept="video/*"
          name="narrative"
          onChange={handleNarrativeChange}
        />
        <div>
          <span className="custom-label">{intl.formatMessage(messages.postProduce)}</span>
          {narrative ? (
            <div>
              <video className="video-preview" autoPlay>
                <source src={URL.createObjectURL(narrative)} type={narrative.type} />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <Button
              labelId={intl.formatMessage(messages.uploadPostProduce)}
              type="secondary"
              onClick={handleNarrativeUpload}
              className="profile-button"
            />
          )}
        </div>
        <div className="categories">
          <span className="custom-label">{intl.formatMessage(messages.categories)}</span>
          <div className="custom-select">
            <Select
              closeMenuOnSelect={false}
              isMulti
              options={categories}
              onChange={handleCategoryChange}
            />
          </div>
        </div>
        <div className="share-container">
          <span className="custom-label">{intl.formatMessage(messages.shareTo)}</span>
          <div className="narrative-tag">
            <span>{intl.formatMessage(messages.narratives)}</span>
            <img src={Check} alt="check" height="10px" />
          </div>
        </div>
      </div>
      <div className="creator-form-error p1">{error}</div>
      <Button
        labelId="form.narrative.submit"
        className="submit-button"
        onClick={handleSubmit}
        disabled={
          some(errors) ||
          !values[fields.title] ||
          !values[fields.description] ||
          !narrative ||
          !values[fields.category_ids] ||
          loading ||
          processLoading
        }
      >
        {(status === LOADING || loading || processLoading) && (
          <div style={{ marginTop: '-15px' }}>
            <Loading type="ball-clip-rotate" />
          </div>
        )}
      </Button>
      <Modal hide={toggle} isShowing={isShowing} className="original-modal">
        <PublishModal handlePublish={handlePublish} />
      </Modal>
      {uploadProgress > 0 && (
        <div style={{ marginTop: '10px' }}>
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            Finishing Upload
          </div>
          <Line percent={uploadProgress} strokeWidth={1} strokeColor="#2400FC" />
          <div style={{ textAlign: 'center', marginTop: '15px', color: 'white' }}>
            {uploadProgress}% Complete
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(AddNarrativeForm);
