import React from 'react';
import cn from 'classnames';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { defineMessages, useIntl } from 'react-intl';
import CreateSeriesForm from 'components/Originals/CreateSeriesForm';
import { BackArrowIcon } from 'components/icons';
import { useModal } from 'hooks';
import Modal from 'components/common/Modal/Modal';
import CancelModal from 'components/CancelModal/CancelModal';

const messages = defineMessages({
  title: { id: 'title.uploadOriginal' }
});

const AddOriginals = () => {
  const intl = useIntl();
  const { toggle, isShowing } = useModal();
  return (
    <div className={cn('create-series')}>
      <BackgroundOpacity opacityColor="black" opacity={0.5} />
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container">
            <div className="back-button" onClick={toggle}>
              <BackArrowIcon />
            </div>
            <h1 className="title">{intl.formatMessage(messages.title)}</h1>
            <CreateSeriesForm />
          </div>
        </div>
        <Modal hide={toggle} isShowing={isShowing} className="original-modal">
          <CancelModal />
        </Modal>
      </div>
    </div>
  );
};

AddOriginals.loadData = async () => {};

export default AddOriginals;
