import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { arrayOf, shape, number } from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import smoothscroll from 'smoothscroll-polyfill';

import { narrativeShape, celebrityShape } from 'constants/propTypesShapes';
import { showNarrativeAd } from 'utils/helpers';
import { useNavigation } from 'hooks';

import NarrativesPlayer from 'components/Players/NarrativesPlayer';

import { useChangingUrl, scrollIntoView } from '../hooks';

const NarrativesVertical = ({ narratives, initialIndex, celebrity }) => {
  const [visiblePlayer, setVisiblePlayer] = useState();
  const { id: celebrityId } = celebrity;
  const { goBack } = useNavigation();

  const firstEpisodeIndex = useMemo(() => initialIndex, []);
  const lastNarrativeSelected = visiblePlayer === narratives.length - 1;

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (initialIndex !== -1) {
      scrollIntoView(initialIndex, 'auto');
      setVisiblePlayer(initialIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useChangingUrl({ narratives, visiblePlayer, celebrityId });

  const next = useCallback(() => {
    lastNarrativeSelected ? goBack() : scrollIntoView(visiblePlayer + 1, 'smooth');
  }, [visiblePlayer, lastNarrativeSelected, goBack]);

  return (
    <div className="slider-container">
      {narratives.map((narrative, index) => (
        <VisibilitySensor key={index} onChange={visible => visible && setVisiblePlayer(index)}>
          <NarrativesPlayer
            goToNext={next}
            className="slide"
            indexId={`jwplayer-${index}`}
            narrative={narrative}
            play={index === visiblePlayer}
            celebrity={celebrity}
            showAds={showNarrativeAd(index - firstEpisodeIndex)}
          />
        </VisibilitySensor>
      ))}
    </div>
  );
};

NarrativesVertical.propTypes = {
  narratives: arrayOf(shape(narrativeShape)).isRequired,
  initialIndex: number,
  celebrity: shape(celebrityShape)
};

export default NarrativesVertical;
