import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { reportSeries } from 'state/actions/seriesActions';

export default () => {
  const dispatch = useDispatch();
  const { status, error } = useStatus(reportSeries);

  const { showErrorToast, showToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('The content was successfully reported!');
      dispatch(reportSeries.reset());
    }
    if (status === ERROR) {
      showErrorToast('Cannot report this content again with the same reason');
      dispatch(reportSeries.reset());
    }
  }, [dispatch, showErrorToast, showToast, status, error]);

  return useCallback(({ seriesId, params }) => dispatch(reportSeries({ seriesId, params })), [
    dispatch
  ]);
};
