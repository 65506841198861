import React, { useState } from 'react';
import { string, bool, func, oneOfType, shape, arrayOf } from 'prop-types';
import cn from 'classnames';

import { useNavigation } from 'hooks';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import { BackArrowIcon, ShareIcon, CrossIcon } from 'components/icons';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import CelebritiesButton from 'components/Players/Controls/CelebritiesButton';

import { white } from 'styles/common/_constants.scss';

const PlayerNavigationBar = ({
  celebrities,
  videoTitle,
  disableSharing,
  onExitPlayer,
  customGoBack,
  shareText,
  title,
  description,
  closeIcon
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const { goBack } = useNavigation();

  const toggleButtons = e => {
    e.stopPropagation();
    setShowIcons(!showIcons);
  };

  const onBackClicked = () => {
    !!onExitPlayer && onExitPlayer();
    customGoBack ? customGoBack() : goBack();
  };

  return (
    <div className="navigation-bar">
      <div className="first-row">
        <div className={cn('back-button', { wide: disableSharing })} onClick={onBackClicked}>
          {closeIcon ? <CrossIcon color={white} /> : <BackArrowIcon />}
        </div>
        {!showIcons && !!title && (
          <div className="flex-center media-title">
            <h4 className="semibold">{title}</h4>
            {!!description && (
              <>
                <span className="separator">|</span>
                <h5>{description}</h5>
              </>
            )}
          </div>
        )}
        <div className={cn('right-content', { 'celebrity-padded': disableSharing })}>
          {disableSharing ? (
            !!celebrities && (
              <CelebritiesButton celebrities={celebrities} onExitPlayer={onExitPlayer} topRow />
            )
          ) : (
            <div className="top-row">
              {showIcons ? (
                <SharingBlock
                  onClose={toggleButtons}
                  shareUrl={window.location.href}
                  videoTitle={videoTitle}
                  shareText={shareText}
                />
              ) : (
                <div className="share-button" onClick={toggleButtons}>
                  <ShareIcon strokeWidth={2} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!disableSharing && !!celebrities && (
        <div className="secondary-row">
          <CelebritiesButton celebrities={celebrities} onExitPlayer={onExitPlayer} />
        </div>
      )}
    </div>
  );
};

PlayerNavigationBar.propTypes = {
  videoTitle: string,
  disableSharing: bool,
  shareText: string,
  onExitPlayer: func,
  customGoBack: func,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  title: string,
  description: string,
  closeIcon: bool
};

export default PlayerNavigationBar;
