import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useSession from 'hooks/useSession';
import { changeCreatorProfileImage } from 'state/actions/userActions';

export default () => {
  const [loading, setLoading] = useState(false);

  const {
    user: { id: celebrityId }
  } = useSession();
  const dispatch = useDispatch();

  const onChangeProfileImage = async image => {
    setLoading(true);

    try {
      const imageFormData = new FormData();
      imageFormData.append('profile_image', image);

      await dispatch(changeCreatorProfileImage(celebrityId, imageFormData));
    } finally {
      setLoading(false);
    }
  };

  return {
    onChangeProfileImage,
    loading
  };
};
