import { MIXPANEL_EVENTS } from 'constants/constants';
import useAnalytics from 'hooks/useAnalytics';

export default (player, seriesTitle, episodeTitle) => {
  const { trackEvent } = useAnalytics();

  const trackTimeViewed = () => {
    trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
      Seconds: Math.round(player.getPosition()),
      'Series Name': seriesTitle,
      'Episode Name': episodeTitle
    });
  };

  return { trackTimeViewed };
};
