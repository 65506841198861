import React, { useState, useEffect } from 'react';
import { shape, func, string, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CSSTransitionGroup } from 'react-transition-group';

import { celebrityInfoShape, episodeShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps, getFullName } from 'utils/helpers';
import useSeriesAnalytics from 'hooks/series/useSeriesAnalytics';

import PlayerNavigationBar from 'components/Players/Controls/PlayerNavigationBar';
import NextEpisodeBanner from 'components/Players/Banners/NextEpisode/NextEpisodeBanner';
import useProgressControls from 'components/Players/Controls/useProgressControls';
import FollowHook from 'components/Players/Banners/Follow/FollowHook';

const PLAYING = 'playing';

const SeriesAdditionalControls = ({
  player,
  seriesTitle,
  episodeTitle,
  hasNext,
  goToNext,
  celebrity,
  nextEpisode,
  creditsStartTime,
  controlsVisible
}) => {
  const { trackTimeViewed } = useSeriesAnalytics(player, seriesTitle, episodeTitle);

  const history = useHistory();
  const intl = useIntl();

  const { id, username, firstName, lastName, following } = celebrity;
  const [showFollowButton, setShowFollowButton] = useState(!following);

  const { showBanner, resetBanner, hideBanner } = useProgressControls(player, creditsStartTime);

  const onFollow = () => {
    setTimeout(() => setShowFollowButton(false), 2000);
  };

  useEffect(() => {
    resetBanner();
  }, [nextEpisode]);

  const pause = () => {
    if (player.getState() === PLAYING) player.pause();
  };

  const play = () => {
    if (player.getState() !== PLAYING) player.play();
  };

  const goToId = () => {
    history.push(routeWithProps(routesPaths.celebrityId, { celebrityId: id, username }));
  };

  const onNextClicked = () => {
    resetBanner();
    trackTimeViewed();
    goToNext(player.getPlaylistIndex());
    player.next();
  };

  return (
    <>
      <div>
        <div
          className={cn('custom-controls controls')}
          style={{ visibility: controlsVisible ? 'visible' : 'hidden' }}
        >
          <PlayerNavigationBar
            celebrities={celebrity}
            videoTitle={episodeTitle}
            title={seriesTitle}
            description={episodeTitle}
            shareText={intl.formatMessage({ id: 'sharing.episode' }, { seriesTitle })}
            onExitPlayer={trackTimeViewed}
          />
        </div>
      </div>
      {showBanner && (
        <div className="episodes-banner">
          <CSSTransitionGroup transitionName="follow-hook-container" transitionLeaveTimeout={2500}>
            {showFollowButton && hasNext && (
              <FollowHook
                celebrity={celebrity}
                label={`${intl.formatMessage({ id: 'user.follow' })} ${getFullName(
                  firstName,
                  lastName
                )}`}
                onModalDisplayed={pause}
                onExit={play}
                onFollow={onFollow}
                key="follow-hook"
              />
            )}
          </CSSTransitionGroup>
          <NextEpisodeBanner
            episode={nextEpisode}
            hasNext={hasNext}
            onClose={hideBanner}
            celebrity={celebrity}
            onClick={hasNext ? onNextClicked : goToId}
          />
        </div>
      )}
    </>
  );
};

SeriesAdditionalControls.propTypes = {
  player: shape({
    on: func.isRequired,
    getState: func.isRequired
  }).isRequired,
  seriesTitle: string.isRequired,
  episodeTitle: string.isRequired,
  controlsVisible: bool,
  hasNext: bool,
  goToNext: func,
  celebrity: shape(celebrityInfoShape),
  nextEpisode: oneOfType([shape(episodeShape), bool]),
  creditsStartTime: string
};

export default SeriesAdditionalControls;
