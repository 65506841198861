import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';

import { useToast } from 'hooks';
import { signUp, setUserToConfirm } from 'state/actions/userActions';
import { routesPaths } from 'constants/routesPaths';
import capitalize from 'lodash/capitalize';

export default customSuccessRequest => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { status, error } = useStatus(signUp);
  const { showToast } = useToast();

  const onSubmit = useCallback(
    user => {
      dispatch(signUp(user));
      dispatch(setUserToConfirm(user));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === SUCCESS) {
      if (customSuccessRequest) {
        customSuccessRequest();
      } else {
        history.push(routesPaths.verifyPhone, { isSignUp: true });
      }
      showToast(intl.formatMessage({ id: 'verifyPhone.sendCodeSuccess' }));
      dispatch(signUp.reset());
    }
  }, [status, dispatch, history, intl, showToast, customSuccessRequest]);

  return {
    onSubmit,
    status,
    error: capitalize(error)
  };
};
