import React from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { getFullName } from 'utils/helpers';
import { getDayAndMonth, getDiffDay } from 'utils/date';
import { MEMBERSHIP_STATUSES } from 'constants/constants';
import { membershipShape } from 'constants/propTypesShapes';
import { useToggle, useClickOutside } from 'hooks';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Crown from 'components/icons/membership/Crown';
import Button from 'components/common/Button/Button';
import Settings from './Settings';

const MembershipCard = ({ membership, cancelMembership }) => {
  const intl = useIntl();
  const [showCancel, toggleCancel] = useToggle(false);

  const ref = useClickOutside(toggleCancel, showCancel);

  const {
    celebrity: { profileImageUrl, firstName, lastName, bannerUrl },
    currentPeriodEndsAt,
    status,
    membershipPlan: { period, name }
  } = membership;

  const { ACTIVE, ACTIVE_CANCELED } = MEMBERSHIP_STATUSES;

  const onCancel = async () => {
    cancelMembership(membership);
    toggleCancel();
  };

  return (
    <div className="membership-card">
      <div className="membership-card-banner">
        <BackgroundOpacity background={bannerUrl} />
        <div className="gradient" />
      </div>
      <div className="membership-card-content">
        {!showCancel && <Settings onSelectCancel={toggleCancel} />}
        <div className="flex content-row">
          <img className="membership-card-avatar" src={profileImageUrl} alt="avatar" />
          <div className="category-semibold">{getFullName(firstName, lastName)}</div>
        </div>
        <div className={cn('cancel-membership', { visible: showCancel })} ref={ref}>
          <h5 className="bold">{intl.formatMessage({ id: 'subscriptions.confirmCancel' })}</h5>
          <div className="flex-center">
            <Button
              labelId="general.cancel"
              onClick={toggleCancel}
              type="secondary"
              size="xsmall"
            />
            <Button labelId="general.yes" onClick={onCancel} type="red" size="xsmall" />
          </div>
        </div>
        <div className="flex-end content-row">
          <div className="flex-1">
            <h4 className="semi-bold membership-title">{name}</h4>
            {status === ACTIVE && (
              <div className="p1">
                {`${intl.formatMessage(
                  { id: 'membershipCard.periodLabel' },
                  { period }
                )} ${getDayAndMonth(currentPeriodEndsAt)} `}
                {intl.formatMessage(
                  { id: 'membershipCard.timeToNextPayment' },
                  {
                    expiry: `${getDiffDay(currentPeriodEndsAt)} ${intl.formatMessage(
                      { id: 'date.days' },
                      { amount: getDiffDay(currentPeriodEndsAt) }
                    )}`
                  }
                )}
              </div>
            )}
            {status === ACTIVE_CANCELED && (
              <div className="p1">
                <span className="red">
                  {`${intl.formatMessage({ id: 'membershipCard.canceled' })}. `}
                </span>
                {`${intl.formatMessage({ id: 'membershipCard.activeUntil' })} ${getDayAndMonth(
                  currentPeriodEndsAt
                )} `}
              </div>
            )}
          </div>
          {status === ACTIVE && (
            <div className="subscription-label">
              <Crown />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MembershipCard.propTypes = {
  membership: shape(membershipShape).isRequired,
  cancelMembership: func.isRequired
};

export default MembershipCard;
