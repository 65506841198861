import { createThunk } from '@rootstrap/redux-tools';

import seriesService from 'services/seriesService';
import parseError from 'utils/parseError';

export const getOneSeries = createThunk('GET_ONE_SERIES', async id => {
  try {
    const { data } = await seriesService.getOneSeries(id);
    return data.series;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const watchedSerie = createThunk('WATCHED_SERIE', ({ serie, episode, time }) => {
  const watchedSerie = { watchedEpisode: { time, ...episode }, ...serie };
  return watchedSerie;
});

export const addSeries = createThunk('ADD_SERIES', async series => {
  try {
    const { data } = await seriesService.addSeries(series);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getTrailerUrlForJWPlayer = createThunk('GET_TRAILER_URL', async video => {
  try {
    const { data } = await seriesService.getVideoUrlForJWPlayer(video.title);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const deleteSeries = createThunk('DELETE_SERIES', async seriesId => {
  try {
    await seriesService.deleteSeries(seriesId);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const reportSeries = createThunk('REPORT_SERIES', async ({ seriesId, params }) => {
  try {
    await seriesService.reportSeries({ seriesId, params });
  } catch ({ response }) {
    throw parseError(response);
  }
});
