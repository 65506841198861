/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import Close from 'components/common/Close/Close';
import Input from 'components/common/Input/Input';
import {
  useClickOutside,
  useForm,
  useIsMobile,
  useTextInputProps,
  useToast,
  useValidation
} from 'hooks';
import { func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { white, black40 } from 'styles/common/_constants.scss';
import { CrownIcon } from 'components/icons';
import { defineMessages, useIntl } from 'react-intl';
import { episodeValidation } from 'utils/constraints';
import Button from 'components/common/Button/Button';
import { getDateTime } from 'utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { addEpisode, getVideoUrlForJWPlayer } from 'state/actions/episodesActions';
import { ERROR, SUCCESS, useStatus } from '@rootstrap/redux-tools';
import { some } from 'lodash';

const FIELDS = {
  title: 'title',
  description: 'description',
  creditsStartTime: 'creditsStartTime',
  membersPrice: 'membersPrice',
  nonMembersPrice: 'nonMembersPrice',
  seriesVideo: 'seriesVideo'
};

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.descriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.titlePlaceholder' },
  logo: { id: 'form.logo' },
  uploadLogo: { id: 'form.uploadLogo' },
  trailer: { id: 'form.trailer' },
  uploadTrailer: { id: 'form.uploadTrailer' },
  cover: { id: 'form.cover' },
  uploadCover: { id: 'form.uploadCover' },
  coverMobile: { id: 'form.coverMobile' },
  uploadCoverMobile: { id: 'form.uploadCoverMobile' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  episodes: { id: 'label.episodes' },
  addEpisode: { id: 'btn.addEpisode' },
  newEpisode: { id: 'form.newEpisode' },
  video: { id: 'form.video' },
  nameYourEpisodePlaceholder: { id: 'title.nameYourEpisodePlaceholder' },
  descriptionEpisodePlaceholder: { id: 'form.descriptionEpisodePlaceholder' },
  creditStartTime: { id: 'form.creditStartTime' },
  hhmmssPlaceholder: { id: 'form.hhmmssPlaceholder' },
  date: { id: 'form.date' },
  time: { id: 'form.time' },
  releaseDateTimeLabel: { id: 'form.releaseDateTimeLabel' },
  setPriceLabel: { id: 'form.setPriceLabel' },
  nonMembers: { id: 'form.nonMembers' },
  members: { id: 'form.members' },
  chooseVideo: { id: 'form.chooseVideo' },
  earlyReleaseMembers: { id: 'form.earlyReleaseMembers' },
  normalRelease: { id: 'form.normalRelease' }
});

const AddEpisodeForm = ({ hide, id, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ref = useClickOutside(hide, id);
  const isMobile = useIsMobile();
  const [exclusive, setExclusive] = useState(false);
  const seriesVideoRef = useRef(null);
  const [seriesVideo, setSeriesVideo] = useState(null);
  const [earlyReleaseDate, setEarlyReleaseDate] = useState();
  const [earlyReleaseTime, setEarlyReleaseTime] = useState();
  const [creditStartTime, setCreditStartTime] = useState('');
  const [releaseDate, setReleaseDate] = useState();
  const [releaseTime, setReleaseTime] = useState();
  const [membersPrice, setMembersPrice] = useState('');
  const [nonMembersP, setNonMembersP] = useState('');
  const { error, status } = useStatus(getVideoUrlForJWPlayer);
  const { episode } = useSelector(({ episodes: { episode } }) => ({ episode }));
  const { showErrorToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      dispatch(addEpisode(episode));
      dispatch(getVideoUrlForJWPlayer.reset());
      handleClose();
    }
    if (status === ERROR) {
      showErrorToast(error);
      dispatch(getVideoUrlForJWPlayer.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleChecked = event => {
    setExclusive(event.currentTarget.checked);
  };

  const validator = useValidation(episodeValidation);

  const onSubmit = async values => {
    values.earlyReleaseDateTime = exclusive
      ? undefined
      : getDateTime(earlyReleaseDate, earlyReleaseTime);
    values.releaseDateTime = getDateTime(releaseDate, releaseTime);
    values.seriesVideo = seriesVideo;
    await dispatch(getVideoUrlForJWPlayer({ ...values, exclusive }));
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        membersPrice: '0',
        nonMembersPrice: '0'
      }
    },
    [onSubmit]
  );

  const nonMembersPrice = values[FIELDS.nonMembersPrice];
  useEffect(() => {
    if (!nonMembersPrice) {
      setValues(prev => ({ ...prev, [FIELDS.membersPrice]: '0' }));
      setMembersPrice('0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonMembersPrice]);

  const handleSeriesVideoUpload = () => {
    seriesVideoRef.current.click();
  };

  const handleSeriesVideoChange = e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setSeriesVideo(file);
      handleValueChange(FIELDS.seriesVideo, file);
    } else {
      showErrorToast('Please upload a valid video file.');
      e.target.value = null;
    }
  };
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const nullNonMembersPrice = !exclusive && (!nonMembersPrice || nonMembersPrice === 0);

  const handleEarlyReleaseDate = e => {
    setEarlyReleaseDate(e.target.value);
  };

  const handleEarlyReleaseTime = e => {
    setEarlyReleaseTime(e.target.value);
  };

  const handleReleaseDate = e => {
    setReleaseDate(e.target.value);
  };

  const handleReleaseTime = e => {
    setReleaseTime(e.target.value);
  };

  const handleClearReleaseDateTime = () => {
    setReleaseDate('');
    setReleaseTime('');
  };

  const handleClearEarlyReleaseDateTime = () => {
    setEarlyReleaseDate('');
    setEarlyReleaseTime('');
  };

  const onChangeCreditsStartTime = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}:${value.slice(5, 7)}`;
    }
    setCreditStartTime(value);
  };

  const onChangeMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(FIELDS.membersPrice, valueWithoutDollar);
      setMembersPrice(value);
    } else {
      setMembersPrice('');
      handleValueChange(FIELDS.membersPrice, '');
    }
  };

  const onChangeNonMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(FIELDS.nonMembersPrice, valueWithoutDollar);
      setNonMembersP(value);
    } else {
      setNonMembersP('');
      handleValueChange(FIELDS.nonMembersPrice, '');
    }
  };
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        {intl.formatMessage(messages.newEpisode)}{' '}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="serie-landing-header-content">
        <div className="series-form">
          <div className="series-form-fields">
            <Input
              name="seriesTitle"
              placeholder={intl.formatMessage(messages.nameYourEpisodePlaceholder)}
              label={intl.formatMessage(messages.title)}
              errors={errors[FIELDS.title]}
              {...inputProps(FIELDS.title)}
            />
            <Input
              name="seriesDescription"
              placeholder={intl.formatMessage(messages.descriptionEpisodePlaceholder)}
              label={intl.formatMessage(messages.description)}
              errors={errors[FIELDS.description]}
              {...inputProps(FIELDS.description)}
            />
            <input
              type="file"
              ref={seriesVideoRef}
              style={{ display: 'none' }}
              accept="video/*"
              onChange={handleSeriesVideoChange}
              name="series_video"
            />
            <div className="input-container upload-div">
              <span className="tag-bold input-label">{intl.formatMessage(messages.video)}</span>
              {seriesVideo ? (
                <div>
                  <video className="video-preview" autoPlay>
                    <source src={URL.createObjectURL(seriesVideo)} type={seriesVideo.type} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <Button
                  labelId={intl.formatMessage(messages.chooseVideo)}
                  type="secondary"
                  onClick={handleSeriesVideoUpload}
                  className="cover-button"
                />
              )}
            </div>
            <Input
              name="creditStartTime"
              placeholder={intl.formatMessage(messages.hhmmssPlaceholder)}
              label={intl.formatMessage(messages.creditStartTime)}
              errors={errors[FIELDS.creditsStartTime]}
              {...inputProps(FIELDS.creditsStartTime)}
              maxLength={8}
              value={creditStartTime}
              onChange={onChangeCreditsStartTime}
            />
            <div className="input-container">
              <div className="form-group">
                <input type="checkbox" id="members" checked={exclusive} onChange={handleChecked} />
                <label htmlFor="members" className="tag-bold input-label">
                  Exclusive for Members
                  <span>
                    <CrownIcon width={20} height={20} />
                  </span>
                </label>
              </div>
            </div>
            {exclusive ? (
              <>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.releaseDateTimeLabel)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        values={earlyReleaseDate}
                        onChange={handleEarlyReleaseDate}
                      />
                      <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        values={earlyReleaseTime}
                        onChange={handleEarlyReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearEarlyReleaseDateTime}>
                      Clear
                    </p>
                    <small>*Leave it empty in order to be published right away.</small>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.setPriceLabel)}
                  </div>
                  <div className={`input-card ${exclusive ? 'active' : ''}`}>
                    <div className="d-flex">
                      <Input
                        name="members"
                        placeholder="$0"
                        label={intl.formatMessage(messages.members)}
                        errors={errors[FIELDS.membersPrice]}
                        {...inputProps(FIELDS.membersPrice)}
                        onChange={onChangeMembersPrice}
                        value={membersPrice}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.earlyReleaseMembers)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        values={earlyReleaseDate || ''}
                        onChange={handleEarlyReleaseDate}
                      />

                      <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        values={earlyReleaseTime || ''}
                        onChange={handleEarlyReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearEarlyReleaseDateTime}>
                      Clear
                    </p>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.normalRelease)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        values={releaseDate || ''}
                        onChange={handleReleaseDate}
                      />
                      <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        values={releaseTime || ''}
                        onChange={handleReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearReleaseDateTime}>
                      Clear
                    </p>
                    <small>*Leave it empty in order to be published right away.</small>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.setPriceLabel)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="nonMembers"
                        placeholder="$0"
                        label={intl.formatMessage(messages.nonMembers)}
                        errors={errors[FIELDS.nonMembersPrice]}
                        {...inputProps(FIELDS.nonMembersPrice)}
                        onChange={onChangeNonMembersPrice}
                        value={nonMembersP}
                      />
                      <Input
                        name="members"
                        placeholder="$0"
                        label={intl.formatMessage(messages.members)}
                        errors={errors[FIELDS.membersPrice]}
                        disabled={nullNonMembersPrice}
                        {...inputProps(FIELDS.membersPrice)}
                        onChange={onChangeMembersPrice}
                        value={membersPrice}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <Button
              labelId={intl.formatMessage(messages.addEpisode)}
              type="secondary"
              onClick={handleSubmit}
              className="in-add-button"
              disabled={
                some(errors) ||
                !values[FIELDS.title] ||
                !values[FIELDS.description] ||
                !seriesVideo ||
                !values[FIELDS.membersPrice]
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddEpisodeForm.propTypes = {
  hide: func.isRequired
};

export default AddEpisodeForm;
