/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { take, keys } from 'lodash';

import { useIntl } from 'react-intl';

import { maxMobileWidth, warning, primaryColor } from 'styles/common/_constants.scss';
import {
  AVATAR_NAME_LENGTH,
  JWPLAYER_URL,
  SHARING_PLATFORMS,
  PERIODS,
  CREDIT_CARDS,
  NARRATIVE_STATES,
  MEMBERSHIP_STATUSES,
  NARRATIVE_AD_COUNT,
  REGEX_HASHTAGS
} from 'constants/constants';
import { toast } from 'react-toastify';
import { routesPaths } from 'constants/routesPaths';

export const parseInputErrors = error => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const getAsQueryParam = (params = {}) => {
  const queryParams = queryString.stringify(params);
  return `?${queryParams}`;
};

export const routeWithProps = (route, props) => {
  let newRoute = route;
  Object.keys(props).forEach(prop => {
    newRoute = newRoute.replace(`:${prop}?`, props[prop]);
    newRoute = newRoute.replace(`:${prop}`, props[prop]);
  });
  return newRoute;
};

export const renderImage = (desktopImage, mobileImage) => {
  if (!desktopImage) return mobileImage;
  if (!mobileImage) return desktopImage;
  const isMobile = window && window.innerWidth <= maxMobileWidth;
  return isMobile ? mobileImage : desktopImage;
};

export const trimString = (string, maxLength) => {
  if (!string) return { shortWord: '', hasMore: false };
  let shortWord = string.slice(0, maxLength);
  const hasMore = shortWord.length < string.length;
  if (hasMore) shortWord = `${shortWord.trim()}...`;
  return { shortWord, hasMore };
};

export const getFullName = (firstName = '', lastName = '') => {
  return `${firstName} ${lastName}`;
};

export const celebrityNameFontSize = (firstName, lastName) => {
  const fullName = getFullName(firstName, lastName).replace(/\s/g, '');

  const LONG_NAME_LIMIT = 30;
  const MEDIUM_NAME_LIMIT = 16;

  if (fullName.length >= LONG_NAME_LIMIT) {
    return 'small';
  }
  if (fullName.length >= MEDIUM_NAME_LIMIT) {
    return 'medium';
  }
  return '';
};

export const avatarName = (firstName = '', lastName = '') => {
  const fullWord = `${firstName} ${lastName?.slice(0, 1)}.`;
  const { shortWord } = trimString(fullWord, AVATAR_NAME_LENGTH);
  return shortWord;
};

export const getPlayerUrl = id => {
  return JWPLAYER_URL.replace(`<id>`, id);
};

export const copyToClipboard = text => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', text);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  toast.success('Copied to clipboard!');
};

export const getSharingUrl = (socialPlatform, url, text) => {
  const { FACEBOOK, TWITTER } = SHARING_PLATFORMS;
  switch (socialPlatform) {
    case FACEBOOK:
      return applyQueryParams(FACEBOOK.sharingUrl, { u: url, quote: text });
    case TWITTER:
      return applyQueryParams(TWITTER.sharingUrl, { url, text });
    default:
  }
};

export const getOriginalLabel = episodeCount => {
  const intl = useIntl();

  return (
    <>
      {`${intl.formatMessage({ id: 'content.original' })} - `}
      <span className="bold">
        {`(${episodeCount} ${intl.formatMessage({ id: 'episodes.abbreviation' })}.)`}
      </span>
    </>
  );
};

export const includeAllFields = (object, fields, touched) => {
  const expected = keys(fields);
  const current = keys(object);
  const allTouched = keys(touched);
  return current.length === expected.length && allTouched.length === expected.length;
};

const getEpisodeInfo = (episode, member) => {
  const {
    id,
    thumbnailsUrls,
    title,
    celebrity: { firstName, lastName },
    series: { id: serieId },
    exclusive,
    earlyReleaseDateTime
  } = episode;

  let label;
  if (exclusive) label = 'notifications.exclusiveTag';
  else if (earlyReleaseDateTime && member) label = 'notifications.earlyTag';
  else label = 'notifications.episodeTag';

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: thumbnailsUrls?.[0]?.src,
    redirect: routeWithProps(routesPaths.series, { id: serieId, episode: id }),
    label,
    labelColor: primaryColor,
    member
  };
};

const getNarrativeInfo = narrative => {
  const {
    id,
    thumbnailsUrls,
    title,
    celebrity: { id: celebrityId, firstName, lastName }
  } = narrative;

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: thumbnailsUrls?.[0]?.src,
    redirect: routeWithProps(routesPaths.narratives, { celebrityId, id }),
    label: 'notifications.narrativesTag',
    labelColor: primaryColor
  };
};

const getSeriesInfo = serie => {
  const {
    id,
    title,
    bannerUrl,
    celebrity: { username, firstName, lastName }
  } = serie;

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: bannerUrl,
    redirect: routeWithProps(routesPaths.celebrityId, { username }),
    search: `series=${id}`,
    label: 'notifications.seriesTag',
    labelColor: primaryColor
  };
};

const getDMInfo = dm => {
  const intl = useIntl();
  const {
    message,
    celebrity: { firstName, lastName, profileImageUrl }
  } = dm;

  return {
    title: intl.formatMessage(
      { id: 'notifications.dm.title' },
      { name: getFullName(firstName, lastName) }
    ),
    message,
    image: profileImageUrl
  };
};

const getLiveInfo = live => {
  const {
    title,
    celebrity: { id, profileImageUrl }
  } = live;

  return {
    title,
    image: profileImageUrl,
    label: 'notifications.liveTag',
    labelColor: warning,
    redirect: routeWithProps(routesPaths.live, { celebrityId: id })
  };
};

const getScheduleLiveInfo = scheduledLive => {
  const { id, title, desktopImageUrl, celebrities } = scheduledLive;

  const VIEWABLE_CELEBRITIES = 3;
  const SEPARATOR = ', ';
  const subtitle = take(celebrities, VIEWABLE_CELEBRITIES).map(({ firstName, lastName }) =>
    getFullName(firstName, lastName)
  );

  return {
    isScheduledLive: true,
    subtitle: subtitle.join(SEPARATOR),
    title,
    image: desktopImageUrl,
    label: 'notifications.liveTag',
    secondLabel: 'notifications.scheduleLiveTag',
    labelColor: warning,
    redirect: routeWithProps(routesPaths.scheduledLive, { id })
  };
};

export const getNotificationInfo = notification => {
  const { narrative, series, episode, dm, live, member, scheduledLive } = notification;
  switch (true) {
    case !!narrative:
      return getNarrativeInfo(narrative);
    case !!series:
      return getSeriesInfo(series);
    case !!episode:
      return getEpisodeInfo(episode, member);
    case !!live:
      return getLiveInfo(live);
    case !!scheduledLive:
      return getScheduleLiveInfo(scheduledLive);
    default:
      return getDMInfo(dm);
  }
};

export const getShortPeriod = period => {
  switch (period) {
    case PERIODS.MONTH:
      return 'mo';
    default:
  }
};

export const checkSubscription = (memberships, celebrityId) => {
  const subscription = memberships?.find(({ celebrity }) => celebrity.id === celebrityId);
  const isActive = !isEmpty(subscription) && subscription.status !== MEMBERSHIP_STATUSES.INACTIVE;
  return isActive;
};

export const getCorrectPlayer = (disableAds = false, unsubscribedPlayer) => {
  return getPlayerUrl(disableAds ? process.env.PLAYER_WITHOUT_ADS_ID : unsubscribedPlayer);
};

export const getMaskedCreditCard = (last4, reducedMask = false) => {
  return reducedMask ? `**** ${last4}` : `**** **** **** ${last4}`;
};

export const getCardIcon = type => {
  const { icon } = CREDIT_CARDS.find(({ name }) => name == type) || {};
  return icon;
};

export const getPublishedNarratives = narratives => {
  const publishedNarratives = narratives.filter(
    ({ stage }) => stage === NARRATIVE_STATES.published
  );
  return publishedNarratives;
};

export const showNarrativeAd = index => index % NARRATIVE_AD_COUNT === 0 && index != 0;

export const fixedPrice = num => num?.toFixed(2);

export const parseHashtags = text => text.match(REGEX_HASHTAGS);
