/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import HashImg from 'assets/icons/Hash/Hash.png';
import LayersImg from 'assets/icons/Layers/Layers.png';
import { useClickOutside, useIsMobile, useNavigation } from 'hooks';
import { routesPaths } from 'constants/routesPaths';

const UploadModal = ({ hide, id }) => {
  const isMobile = useIsMobile();
  const { goTo } = useNavigation();
  const ref = useClickOutside(hide, id);
  const goToNarratives = () => goTo(routesPaths.addNarratives);
  const goToSeries = () => goTo(routesPaths.addOriginals);
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        Upload Options <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <ul>
          <li onClick={goToNarratives} style={{ cursor: 'pointer' }}>
            <div className="d-flex">
              <div className="icon">
                <img src={HashImg} alt="videoImg" width={20} height={20} />
              </div>
              Narratives
            </div>
          </li>
          <li onClick={goToSeries} style={{ cursor: 'pointer' }}>
            <div className="d-flex">
              <div>
                <div className="icon">
                  <img src={LayersImg} alt="clockImg" width={20} height={20} />
                </div>
                Series
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UploadModal;
