// App common constants
import React from 'react';

import { FacebookIcon, TwitterIcon, YoutubeIcon, InstagramIcon } from 'components/icons';

import VisaIcon from 'assets/credit-card-icons/visa.png';
import MasterCardIcon from 'assets/credit-card-icons/master.png';
import AmericanExpressIcon from 'assets/credit-card-icons/american-express.png';
import DinersIcon from 'assets/credit-card-icons/diners.png';
import JCBIcon from 'assets/credit-card-icons/jcb.png';
import DiscoverIcon from 'assets/credit-card-icons/discover.png';

export const DEFAULT_PAGE_TITLE = 'Identifi';
export const { CANONICAL_URL } = process.env;
export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';

export const COPYRIGHT_MESSAGE = '© 2020 Identifi, Inc.';
export const VIDEO_SHORT_DESCRIPTION_LENGTH = 72;
export const BIO_DESCRIPTION_LENGTH = 82;
export const AVATAR_NAME_LENGTH = 8;

export const RIGHT_ARROW_KEY = 39;
export const LEFT_ARROW_KEY = 37;
export const SPACE_KEY = 32;

export const NARRATIVE_AD_COUNT = 2;

export const MEDIA_INQUIRIES_EMAIL = 'media@weidentifi.com';
export const SUPPORT_EMAIL = 'support@weidentifi.com';

export const JWPLAYER_URL = 'https://cdn.jwplayer.com/libraries/<id>.js';

export const OPEN_SANS_SRC =
  'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap';

export const SHARING_PLATFORMS = {
  TWITTER: { id: 1, sharingUrl: 'https://twitter.com/intent/tweet', name: 'Twitter' },
  FACEBOOK: { id: 2, sharingUrl: 'https://www.facebook.com/sharer/sharer.php', name: 'Facebook' }
};

export const SOCIAL_PLATFORMS = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/IdentifiPresents/',
    icon: <FacebookIcon />
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/teamidentifi',
    icon: <TwitterIcon />
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCaaSRuACAmAnlQ5bShA1RwQ',
    icon: <YoutubeIcon />
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/identifipresents/',
    icon: <InstagramIcon />
  }
];

export const CREDIT_CARDS = [
  {
    name: 'Visa',
    icon: VisaIcon
  },
  {
    name: 'MasterCard',
    icon: MasterCardIcon
  },
  {
    name: 'Diners Club',
    icon: DinersIcon
  },
  {
    name: 'Discover',
    icon: DiscoverIcon
  },
  {
    name: 'JCB',
    icon: JCBIcon
  },
  {
    name: 'American Express',
    icon: AmericanExpressIcon
  }
];

export const PERIODS = {
  MONTH: 'monthly'
};

export const MIXPANEL_EVENTS = {
  home: 'Home View',
  creatorID: 'ID View',
  showEpisodes: 'Show Episodes Clicked',
  playFirstEpisode: 'Play First Episode Clicked',
  narrativesListView: 'Narratives List View',
  nextEpisode: 'Next episode clicked',
  homeHighlight: 'Home Highlight Clicked',
  category: 'Category View',
  shareContent: 'Share Content Clicked',
  keepWatching: 'Keep Watching Clicked',
  featuredCreator: 'Featured Creator Clicked',
  seriesDetails: 'Series Details View',
  followClicked: 'Follow Clicked',
  signUp: 'Sign Up View',
  signUpCompleted: 'Sign Up Completed',
  login: 'Login View',
  notFound: '404 Page View',
  privacyPolicy: 'Privacy Policies View',
  terms: 'Terms of Service View',
  liveAvatar: 'Live Avatar Clicked',
  liveView: 'Live Event View',
  membershipView: 'Basic membership View',
  membershipCanceled: 'Membership Canceled',
  subscriptionStarted: 'Subscription Checkout Started',
  subscriptionConfirmed: 'Subscription Checkout Confirmed',
  creditCardAdded: 'Credit card added',
  creditCardRejected: 'Credit card rejected',
  creditCardSelected: 'Credit Card Selected,',
  episodePlayerView: 'Episode Player View',
  narrativePlayerView: 'Narrative Player View',
  videoTimeWatched: 'Video Time Watched',
  loggedIn: 'Logged in',
  addPaymentMethodClicked: 'Add Payment Method Clicked',
  completeSubscriptionClicked: 'Complete Subscription Clicked',
  shopifyClicked: 'Shopify Link Clicked',
  playTrailer: 'Play Live Trailer',
  playScheduledLive: 'Play Scheduled Live Event',
  buyInfoPPVClicked: 'Buy Info PPV Live Clicked',
  buyNowPPVClicked: 'Buy  Now PPV Live Clicked',
  confirmPPVLivePurchase: 'Confirm PPV Live Purchase',
  scheduledLiveEventView: 'Scheduled Live Event View',
  episodeBought: 'Episode Bought'
};

export const celebrityTabs = {
  ORIGINALS: { id: 1, localeId: 'tab.originals' },
  NARRATIVES: { id: 2, localeId: 'tab.narratives' }
};

export const settingTabs = {
  MANAGE_ACCOUNT: { id: 1, localeId: 'tab.manageAccount' },
  CHANGE_PROFILE_IMAGE: { id: 2, localeId: 'tab.changeProfileImage' }
};

export const settingTabsForUser = {
  MANAGE_ACCOUNT: { id: 1, localeId: 'tab.manageAccount' }
};

export const serieTabs = {
  DETAILS: { id: 1, localeId: 'tab.details' },
  EPISODES: { id: 2, localeId: 'tab.episodes' }
};

export const NARRATIVE_STATES = {
  published: 'published',
  processing: 'processing',
  postProducing: 'post_produce_processing'
};

export const accountTabs = {
  SUBSCRIPTIONS: { id: 1, localeId: 'tab.subscriptions', tab: 'subscriptions' },
  PAYMENTS: { id: 2, localeId: 'tab.payments', tab: 'payments' },
  SETTINGS: { id: 3, localeId: 'tab.settings', tab: 'settings' }
};

export const legalTabs = {
  LINK_OF_INTEREST: { id: 1, localeId: 'tab.linkOfInterest', tab: 'links_of_interest' },
  PRIVACY: { id: 2, localeId: 'tab.privacy', tab: 'privacy' },
  TERMS: { id: 3, localeId: 'tab.terms', tab: 'terms' }
};

export const MEMBERSHIP_STATUSES = {
  ACTIVE: 'active',
  ACTIVE_CANCELED: 'active_canceled',
  INACTIVE: 'inactive'
};

export const LIVE_STATUSES = {
  STARTED: 'started',
  UNSTARTED: 'unstarted'
};

const JWPLAYER_URL_FOR_LIVES = 'https://cdn.jwplayer.com';
export const JWPLAYER_LIVE_CHANNELS_URL = `${JWPLAYER_URL_FOR_LIVES}/live/channels/`;
export const JWPLAYER_MEDIA_URL = `${JWPLAYER_URL_FOR_LIVES}/v2/media/`;

export const PURCHASE_TYPES = {
  MEMBERSHIP: 'MEMBERSHIP',
  EPISODE: 'EPISODE',
  MEMBERSHIP_EPISODE: 'MEMBERSHIP_EPISODE'
};

export const REGEX_HASHTAGS = /(#\w+)/g;
