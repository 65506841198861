import { useEffect, useCallback } from 'react';
import { RIGHT_ARROW_KEY, LEFT_ARROW_KEY } from 'constants/constants';

export default (next, previous, enableNext, adPlaying) => {
  const handleUserKeyPress = useCallback(
    event => {
      if (!adPlaying) {
        const { keyCode } = event;
        switch (keyCode) {
          case RIGHT_ARROW_KEY:
            enableNext && next();
            break;
          case LEFT_ARROW_KEY:
            previous();
            break;
          default:
        }
      }
    },
    [next, previous, adPlaying]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
};
