import { useCallback, useEffect, useState } from 'react';

import { CANONICAL_URL } from 'constants/constants';
import useSeriesAnalytics from 'hooks/series/useSeriesAnalytics';

import Next from 'assets/next.svg';

const NEXT_BUTTON = 'next-button';

export default ({
  episodes,
  index,
  config,
  playerDiv,
  playerUrl,
  offset = false,
  mute = false,
  title,
  goToNext,
  controlsVisible
}) => {
  const [player, setPlayer] = useState();
  const [error, setError] = useState(false);
  const { trackTimeViewed } = useSeriesAnalytics(player, title, episodes[index].description);

  const hasNext = useCallback(index => episodes.length > index + 1, [episodes.length]);

  const nextAction = () => {
    trackTimeViewed();
    const currentIndex = player.getPlaylistIndex();
    hasNext(currentIndex) && player.next();
    goToNext(currentIndex);
  };

  const checkNextButton = () => {
    const isLastEpisode = episodes.length <= player.getPlaylistIndex() + 1;
    if (isLastEpisode) {
      player.removeButton(NEXT_BUTTON);
    }
  };

  useEffect(() => {
    hasNext(index) &&
      player &&
      player.addButton(Next, 'Next episode', nextAction, NEXT_BUTTON, 'next-button');
  }, [player, hasNext]);

  useEffect(() => {
    const HIDDEN_CONTROLS_STYLE = 'jw-flag-controls-hidden';
    const INACTIVE_USER_STYLE = 'jw-flag-user-inactive';
    if (controlsVisible) {
      document.getElementById(playerDiv)?.classList.remove(HIDDEN_CONTROLS_STYLE);
      document.getElementById(playerDiv)?.classList.remove(INACTIVE_USER_STYLE);
    } else {
      document.getElementById(playerDiv)?.classList.add(HIDDEN_CONTROLS_STYLE);
      document.getElementById(playerDiv)?.classList.add(INACTIVE_USER_STYLE);
    }
  }, [controlsVisible, playerDiv]);

  useEffect(() => {
    if (player) {
      player.on('firstFrame', () => {
        !!offset && offset < player.getDuration() && player.seek(offset);
      });
      player.on('beforePlay', checkNextButton);
      player.on('complete', nextAction);
      player.on('error', () => setError(true));
    }
    return () => {
      if (player) {
        player.off('firstFrame');
        player.off('error');
        player.off('beforePlay');
      }
    };
  }, [player, offset]);

  useEffect(() => {
    const setup = () => {
      const jw = window?.jwplayer(playerDiv);
      if (jw && jw.setup) {
        jw.setup({
          mute,
          playlist: episodes.map(({ link, title }) => {
            return {
              file: link,
              title,
              description: title
            };
          }),
          displaytitle: false,
          displaydescription: false,
          height: '100%',
          width: '100%',
          autostart: 'viewable',
          aboutlink: CANONICAL_URL,
          playlistIndex: index,
          nextUpDisplay: true,
          related: {
            displayMode: 'none'
          },
          ...config
        });
        setPlayer(jw);
      }
    };

    const previousPlayer = document.getElementById('identifi-player');
    if (previousPlayer) previousPlayer.remove();
    const jwPlayerScript = document?.createElement('script');
    jwPlayerScript.id = 'identifi-player';
    jwPlayerScript.src = playerUrl;
    document.head.appendChild(jwPlayerScript);

    jwPlayerScript.onload = setup;
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      jwPlayerScript && jwPlayerScript.remove();
    };
  }, [playerDiv, playerUrl]);

  return { player, error };
};
