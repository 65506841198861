/* eslint-disable no-nested-ternary */
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { string, shape, func } from 'prop-types';

import { PlayIcon } from 'components/icons';
import PriceCrownIcon from 'components/common/PriceCrownIcon';

import { episodeShape } from 'constants/propTypesShapes';

import { useCelebrity } from 'hooks';
import useProfile from 'hooks/users/useProfile';

import { checkSubscription } from 'utils/helpers';

import { white } from 'styles/common/_constants.scss';

const EpisodeCard = ({ episode, className, onClick }) => {
  const { celebrity } = useCelebrity();
  const {
    thumbnailsUrls = [],
    title,
    description,
    durationMinutes,
    exclusive,
    link,
    membersPrice,
    nonMembersPrice
  } = episode || {};
  const intl = useIntl();

  const {
    profile: { memberships }
  } = useProfile();

  const isMember = checkSubscription(memberships, celebrity.id);

  const shouldShowPriceTag =
    (!!membersPrice && isMember && !link) ||
    (!link && !isMember && !!nonMembersPrice && !exclusive) ||
    (!link && !isMember && exclusive && !!membersPrice);

  return (
    <div className={cn('episode-card', className)} onClick={onClick}>
      <div className="episode-card-thumbnail">
        {thumbnailsUrls && (
          <img
            src={thumbnailsUrls[0]?.src}
            className={cn('episode-card-thumbnail', { grayscale: !link })}
            alt="episode"
          />
        )}
        <div className="episode-card-thumbnail-gradient" />
        <div className="opacity" />
        {link && (
          <div className="layer flex-center play-icon">
            <PlayIcon fill color={white} />
          </div>
        )}
        {!link && (
          <PriceCrownIcon
            price={
              shouldShowPriceTag
                ? isMember
                  ? membersPrice
                  : nonMembersPrice || membersPrice
                : null
            }
            membership={!isMember && episode.exclusive}
            className="episode-card-thumbnail-premium"
          />
        )}
      </div>
      <div className="episode-card-title">
        <h5 className="bold">{title}</h5>
        <div className="p2 bold">{`${durationMinutes} ${intl.formatMessage({
          id: 'duration.minutes'
        })}`}</div>
      </div>
      <div className="p1">{description}</div>
    </div>
  );
};

EpisodeCard.propTypes = {
  episode: shape(episodeShape).isRequired,
  className: string,
  onClick: func.isRequired
};

export default EpisodeCard;
