import React, { useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { withRouter } from 'react-router';

const ModalContext = React.createContext([{}, () => {}]);

const ModalContextProviderComponent = ({ children }) => {
  const [menuState, setMenuState] = useState({
    isMenuOpen: false
  });

  return (
    <ModalContext.Provider value={[menuState, setMenuState]}>{children}</ModalContext.Provider>
  );
};

ModalContextProviderComponent.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

const ModalContextProvider = withRouter(ModalContextProviderComponent);

export { ModalContext, ModalContextProvider };
