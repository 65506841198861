import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';
import Button from 'components/common/Button/Button';
import { func } from 'prop-types';
import { routesPaths } from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';

const CancelModal = ({ hide }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const history = useHistory();

  return (
    <div className="cancel-modal" ref={ref}>
      <div className="cancel-modal-header">
        Cancel Series Creation
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer cancel-modal-header-content">
        <div className="cancel-content-div">
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            Cancel this Series creation?
          </span>
          <div className="buttons">
            <Button className="cancel-btn" onClick={hide}>
              Go Back
            </Button>
            <Button
              type="primary"
              labelId="Yes, Cancel"
              className="delete-btn"
              onClick={() => history.push(routesPaths.home)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CancelModal.propTypes = {
  hide: func.isRequired
};

export default CancelModal;
