import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import useProfile from 'hooks/users/useProfile';

import MembershipCard from 'components/membership/MembershipCard/MembershipCard';
import EmptyMessage from 'components/common/EmptyMessage/EmptyMessage';
import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import useMembership from 'hooks/celebrities/useMembership';

import { MEMBERSHIP_STATUSES } from 'constants/constants';

const Subscriptions = ({ isModal, hide }) => {
  const intl = useIntl();
  const {
    profile: { memberships }
  } = useProfile();
  const { cancelMembership } = useMembership();

  const { INACTIVE } = MEMBERSHIP_STATUSES;

  const activeMemberships = useMemo(
    () => memberships?.filter(({ status }) => status !== INACTIVE) || [],
    [memberships, INACTIVE]
  );

  return (
    <div className={cn('subscriptions', { 'subscriptions-modal': isModal })}>
      {isModal ? (
        <ModalHeader titleId="subscriptions.title" hideModal={hide} />
      ) : (
        <h1 className="title semi-bold">{intl.formatMessage({ id: 'subscriptions.title' })}</h1>
      )}
      {activeMemberships && (
        <>
          <div className="subscriptions-container">
            {activeMemberships.map(membership => (
              <MembershipCard
                membership={membership}
                key={membership.id}
                cancelMembership={cancelMembership}
              />
            ))}
          </div>
          {isEmpty(activeMemberships) &&
            (isModal ? (
              <EmptyMessage titleId="subscriptions.empty" />
            ) : (
              <h5 className="empty-title">{intl.formatMessage({ id: 'subscriptions.empty' })}</h5>
            ))}
        </>
      )}
    </div>
  );
};

Subscriptions.propTypes = {
  isModal: bool,
  hide: func
};

export default Subscriptions;
