import validate from 'validate.js';

validate.validators.presence.options = { allowEmpty: false };

validate.validators.digitsOnly = (value, options, key) => {
  if (value && !/^\d+$/.test(value)) {
    return options.message || `${key} must contain only digits`;
  }
};

export const validations = (constraints, props = {}) => data =>
  validate(data, constraints, props) || {};

export const login = {
  phone: {
    presence: true
  }
};

export const signup = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  email: {
    presence: true,
    email: true
  },
  phone: {
    presence: true
  }
};

export const billing = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  zipcode: {
    presence: true
  },
  country: {
    presence: true
  }
};

export const creator = {
  username: {
    presence: true
  },
  membershipPrice: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 1,
      lessThan: 1000000
    }
  }
};

export const createNarrativeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  }
};

export const createSeriesValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  }
};

export const episodeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  creditsStartTime: {
    format: {
      pattern: /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
      message: 'must have the format HH:MM:SS'
    }
  }
};
