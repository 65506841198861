import React, { memo } from 'react';
import { string, func, node } from 'prop-types';

const VideoThumbnail = ({ thumbnail, className, onClick, children }) => (
  <div className={className} onClick={onClick}>
    <img src={thumbnail} alt="narrative" />
    {children}
  </div>
);

VideoThumbnail.propTypes = {
  thumbnail: string.isRequired,
  className: string,
  onClick: func.isRequired,
  children: node
};

export default memo(VideoThumbnail);
