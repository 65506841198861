import httpClient from 'httpClient';

class CategoriesService {
  getCategoriesFeatured() {
    return httpClient.get('/categories/featured');
  }

  getCategory(slug) {
    return httpClient.get(`/categories/${slug}/explore`);
  }

  getCategories() {
    return httpClient.get(`/categories`);
  }
}

export default new CategoriesService();
