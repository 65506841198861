import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { useCelebrity, useToast, useAnalytics, useIsMobile, useSession } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { getCelebrity } from 'state/actions/celebritiesActions';
import { CANONICAL_URL, MIXPANEL_EVENTS } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';

import NarrativesHorizontalSlider from 'components/narratives/NarrativesHorizontalSlider';
import NarrativesVerticalSlider from 'components/narratives/NarrativesVerticalSlider';
import Loading from 'components/common/Loading';
import Metadata from 'components/common/Metadata';

import NotFoundPage from 'pages/NotFoundPage';
import { getFullName } from 'utils/helpers';

const NarrativesPage = () => {
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { celebrityId, id } = useParams();
  const { trackEvent } = useAnalytics();
  const isMobile = useIsMobile();
  const { requestProfile, loading: profileLoading } = useProfile();
  const { authenticated } = useSession();

  const dispatch = useDispatch();

  const { celebrity, error } = useCelebrity();
  const { narratives } = celebrity;

  const resetCelebrity = celebrity.id !== Number(celebrityId);
  const narrative = useMemo(() => narratives?.find(item => item.id == id), [narratives, id]);
  const narrativeUnavailable = !narrative && !resetCelebrity;

  useEffect(() => {
    authenticated && requestProfile();
    (!celebrity || resetCelebrity) && dispatch(getCelebrity(celebrityId));
  }, []);

  useEffect(() => {
    isEmpty(celebrity) && dispatch(getCelebrity(celebrityId));
  }, [celebrity?.firstName]);

  useEffect(() => {
    if (narrativeUnavailable) {
      showErrorToast(intl.formatMessage({ id: 'narratives.narrativeUnavailable' }));
    }
    if (narrative && !resetCelebrity) {
      const { firstName, lastName } = celebrity;
      trackEvent(MIXPANEL_EVENTS.narrativePlayerView, {
        'Creator Name': getFullName(firstName, lastName),
        'Narrative Title': narrative.title
      });
    }
  }, [narrative, resetCelebrity, narrativeUnavailable]);

  if (error) return <NotFoundPage />;

  if (isEmpty(narratives) || resetCelebrity || profileLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  let currentIndex = narratives.findIndex(narrative => narrative.id.toString() === id);
  if (currentIndex === -1) currentIndex = 0;

  if (narrativeUnavailable) {
    return <Redirect to={routesPaths.home} />;
  }

  const { id: narrativeId, title, description, thumbnailsUrls } = narrative;

  return (
    <>
      <Metadata
        socialMediaMetadata={{
          description,
          title,
          image: thumbnailsUrls?.[1]?.src
        }}
        canonicalUrl={`${CANONICAL_URL}/narratives/${celebrity.id}/${narrativeId}`}
      />
      <div className="video-frame">
        <>
          {isMobile ? (
            <NarrativesVerticalSlider
              narratives={narratives}
              initialIndex={currentIndex}
              celebrity={celebrity}
            />
          ) : (
            <NarrativesHorizontalSlider
              narratives={narratives}
              initialIndex={currentIndex}
              celebrity={celebrity}
            />
          )}
        </>
      </div>
    </>
  );
};

NarrativesPage.loadData = async (store, match) => {
  const {
    params: { celebrityId }
  } = match;
  await store.dispatch(getCelebrity(celebrityId));
};

export default NarrativesPage;
