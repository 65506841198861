import httpClient from 'httpClient';

import { applyQueryParams } from 'utils/helpers';

class MembershipService {
  createMembership(celebrityId, membershipId, creditCardId) {
    const url = applyQueryParams(`celebrities/${celebrityId}/memberships`, {
      credit_card_id: creditCardId,
      membership_plan_id: membershipId
    });
    return httpClient.post(url);
  }

  cancelMembership(celebrityId, membershipId) {
    return httpClient.put(`/celebrities/${celebrityId}/memberships/${membershipId}/cancel`);
  }

  buyEpisode({ episodeId, creditCardId }) {
    return httpClient.post(`/ppvs/episodes`, {
      episode_id: episodeId,
      credit_card_id: creditCardId
    });
  }
}

export default new MembershipService();
