import React, { useRef } from 'react';
import Button from 'components/common/Button/Button';
import { useToast } from 'hooks';
import Loading from 'components/common/Loading';
import useChangeProfileImage from 'hooks/users/useChangeProfileImage';

const ChangeProfile = () => {
  const fileInputRef = useRef(null);
  const { showToast } = useToast();
  const { loading, onChangeProfileImage } = useChangeProfileImage();

  const handleFileChange = async event => {
    const file = event.target.files[0];
    await onChangeProfileImage(file);
    showToast('Profile Picture Changed Successfully!');
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="change-profile">
      <div>
        <Button className="profile-button" onClick={handleClick} disabled={loading}>
          Change Profile Image
          {loading && <Loading type="ball-clip-rotate" />}
        </Button>
        <input
          id="profile-upload"
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default ChangeProfile;
