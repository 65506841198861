import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { getProfile } from 'state/actions/userActions';

import { ArrowIcon, BackArrowIcon } from 'components/icons';
import UserHeader from 'components/subscribe/UserHeader';
import Accordion from 'components/subscribe/Accordion';
import celebritiesService from 'services/celebritiesService';
import Loading from 'components/common/Loading';

const Metrics = () => {
  const intl = useIntl();
  const { goBack } = useNavigation();
  const { requestProfile, profile } = useProfile();
  const [loading, setLoading] = useState(false);

  const [metricsData, setMetricsData] = useState();

  useEffect(() => {
    setLoading(true);
    requestProfile();
  }, []);

  const getMetricsVideoData = async () => {
    const { data } = await celebritiesService.getMetrics(profile.id);
    setMetricsData(data);
    setLoading(false);
  };
  useEffect(() => {
    getMetricsVideoData();
  }, []);

  return (
    <>
      {loading && (
        <div className="home-loading">
          <Loading />
        </div>
      )}
      {!loading && (
        <div className="account">
          <div className="banner-top-bar">
            <div className="back-button" onClick={goBack}>
              <BackArrowIcon />
              <h5 className="bold">{intl.formatMessage({ id: 'general.back' })}</h5>
            </div>
          </div>
          <div className="account-content">
            <div className="account-content-left">
              <UserHeader metricsData={metricsData} />
            </div>
            <div className="accordion-content-right">
              <div className="accordion__wrapper">
                <h2>Videos</h2>
                {metricsData?.contentMetrics?.map((item, index) => {
                  return (
                    <Accordion
                      key={index}
                      title={`${
                        item?.episode
                          ? `${item?.episode?.title} - ${item?.episode?.seriesTitle}`
                          : item?.narrative?.title
                      }`}
                    >
                      <ul>
                        <li>
                          <ArrowIcon />
                          {item?.totalViews}
                        </li>
                        <li>
                          <ArrowIcon />
                          {item?.timeWatched}s
                        </li>
                        <li>
                          <ArrowIcon />
                          {item?.completeRate}%
                        </li>
                      </ul>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Metrics.loadData = async store => {
  await store.dispatch(getProfile());
};

export default Metrics;
