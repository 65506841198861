import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { func } from 'prop-types';

import { routesPaths } from 'constants/routesPaths';
import { useSession, useNavigation } from 'hooks';
import { accountTabs } from 'constants/constants';

import { LogoutIcon, CrownIcon, CreditCardIcon, SettingsIcon } from 'components/icons';
import DropdownRow from 'components/header/Dropdown/DropdownRow';
import Dropdown from 'components/header/Dropdown/Dropdown';
import { routeWithProps } from 'utils/helpers';
import useLogout from 'hooks/users/useLogout';

const { PAYMENTS, SUBSCRIPTIONS, SETTINGS } = accountTabs;

const UserDropdown = ({ toggleModal }) => {
  const intl = useIntl();
  const history = useHistory();
  const { goTo } = useNavigation();
  const { authenticated } = useSession();
  const { logout } = useLogout();

  const logoutClick = () => {
    logout();
    toggleModal();
    history.push(routesPaths.home);
  };

  return (
    <Dropdown className="user-dropdown">
      {authenticated ? (
        <>
          <DropdownRow
            label={intl.formatMessage({ id: 'navbar.subscriptions' })}
            onClick={() =>
              goTo(routeWithProps(routesPaths.account, { tab: SUBSCRIPTIONS.tab }), true)
            }
            icon={<CrownIcon width={20} height={20} />}
          />
          <DropdownRow
            label={intl.formatMessage({ id: 'navbar.paymentMethod' })}
            onClick={() => goTo(routeWithProps(routesPaths.account, { tab: PAYMENTS.tab }), true)}
            icon={<CreditCardIcon width={20} height={20} />}
          />
          <DropdownRow
            label={intl.formatMessage({ id: 'navbar.settings' })}
            onClick={() => goTo(routeWithProps(routesPaths.account, { tab: SETTINGS.tab }), true)}
            icon={<SettingsIcon width={20} height={20} />}
          />
          <DropdownRow
            label={intl.formatMessage({ id: 'user.logout' })}
            icon={<LogoutIcon />}
            className="light"
            onClick={logoutClick}
          />
        </>
      ) : (
        <DropdownRow
          label={intl.formatMessage({ id: 'user.signin' })}
          onClick={() => history.push(routesPaths.login)}
        />
      )}
    </Dropdown>
  );
};

UserDropdown.propTypes = {
  toggleModal: func.isRequired
};

export default UserDropdown;
