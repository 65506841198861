import { useCallback, useEffect } from 'react';
import { watchedSerie } from 'state/actions/seriesActions';

import { useDispatch } from 'hooks';

const TRACK_TIME = 10000;

export default (player, series, video) => {
  const watchedSerieRequest = useDispatch(watchedSerie);

  const trackTiming = useCallback(
    player => {
      const time = Math.round(player.getPosition());
      time > 10 && watchedSerieRequest({ serie: series, episode: video, time: time - 10 });
    },
    [series, video, watchedSerieRequest]
  );

  useEffect(() => {
    const interval = setInterval(() => trackTiming(player), TRACK_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [player, trackTiming]);
};
