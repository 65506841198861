import React, { useRef, useMemo, useCallback } from 'react';
import { shape, string, number, oneOfType } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { useAnalytics, useSession } from 'hooks';
import { routeWithProps, getCorrectPlayer, checkSubscription } from 'utils/helpers';
import useProfile from 'hooks/users/useProfile';
import useSeriesPlayer from 'hooks/series/useSeriesPlayer';
import useDisappearingControls from 'hooks/useDisappearingControls';
import useTrackSerie from 'hooks/series/useTrackSerie';

import SeriesAdditionalControls from 'components/Players/Controls/SeriesAdditionalControls';
import ReactPlayer from 'react-player';

const playerDiv = 'serie-player';

const SeriesPlayer = ({ series, episodeIndex }) => {
  const history = useHistory();
  const { replace } = history;
  const { trackEvent } = useAnalytics();
  const containerRef = useRef();
  const { handleMouseMove, controlsVisible, handleClick } = useDisappearingControls(true);

  const { search, state } = useLocation();

  const { t: offset } = queryString.parse(search);
  const {
    profile: { memberships }
  } = useProfile();
  const { id, episodes, title, celebrity } = series;
  const { user } = useSession();
  const isSubscribed = checkSubscription(memberships, celebrity.id) || user?.id === celebrity.id;

  const video = episodes[episodeIndex];

  const hasNext = useMemo(() => episodes.length > Number(episodeIndex) + 1, [
    episodes.length,
    episodeIndex
  ]);

  const nextEpisode = useMemo(() => hasNext && episodes[Number(episodeIndex) + 1], [
    hasNext,
    episodes,
    episodeIndex
  ]);

  const episodeHasNext = useCallback(index => episodes.length > index + 1, [episodes.length]);

  const goToNext = currentIndex => {
    const currentEpisode = episodes[currentIndex];

    trackEvent(MIXPANEL_EVENTS.nextEpisode, {
      'Series name': title,
      'Current episode': currentEpisode.title
    });
    const { prevPath } = state || {};

    if (episodeHasNext(currentIndex)) {
      replace({
        pathname: routeWithProps(routesPaths.series, {
          id,
          episode: episodes[currentIndex + 1].id
        }),
        state: { prevPath }
      });
    } else {
      const { username } = celebrity;
      routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() });
    }
  };

  const { player } = useSeriesPlayer({
    episodes,
    index: episodeIndex,
    playerDiv,
    file: video.link,
    playerUrl: getCorrectPlayer(isSubscribed, process.env.PLAYER_WITH_ADS_ID),
    offset,
    goToNext,
    title,
    description: video.title,
    controlsVisible
  });

  useTrackSerie(player, series, video);

  const videoUrl = video?.link;

  return (
    <div
      ref={containerRef}
      className={cn('video-container', { 'no-pointer': isMobile })}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      {/* <div id={playerDiv} /> */}
      <ReactPlayer
        playsinline
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={videoUrl}
      />
      {player && (
        <SeriesAdditionalControls
          seriesTitle={title}
          episodeTitle={video.title}
          player={player}
          hasNext={hasNext}
          nextEpisode={nextEpisode}
          goToNext={goToNext}
          celebrity={celebrity}
          creditsStartTime={video.creditsStartTime}
          controlsVisible={controlsVisible}
        />
      )}
    </div>
  );
};

SeriesPlayer.propTypes = {
  series: shape(serieShape).isRequired,
  episodeIndex: oneOfType([string, number])
};

SeriesPlayer.defaultProps = {
  episodeIndex: 0
};

export default SeriesPlayer;
