/* eslint-disable no-nested-ternary */
import React from 'react';
import { shape, string, func } from 'prop-types';
import cn from 'classnames';

import PriceCrownIcon from 'components/common/PriceCrownIcon';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { episodeShape } from 'constants/propTypesShapes';

import { useCelebrity } from 'hooks';
import useProfile from 'hooks/users/useProfile';

import { checkSubscription } from 'utils/helpers';

const EpisodeThumbnail = ({
  episode: { thumbnailsUrls, title, exclusive, link, membersPrice, nonMembersPrice },
  className,
  onClick
}) => {
  const {
    profile: { memberships }
  } = useProfile();
  const { celebrity } = useCelebrity();
  const isMember = checkSubscription(memberships, celebrity.id);

  const shouldShowPriceTag =
    (!!membersPrice && isMember && !link) ||
    (!link && !isMember && !!nonMembersPrice && !exclusive) ||
    (!link && !isMember && exclusive && !!membersPrice);

  return (
    <div className={cn('episode-thumbnail', className)} onClick={onClick}>
      <BackgroundOpacity grayscale={!link} background={thumbnailsUrls[0]?.src} opacity={0.4} />
      {!link && (
        <PriceCrownIcon
          price={
            shouldShowPriceTag ? (isMember ? membersPrice : nonMembersPrice || membersPrice) : null
          }
          membership={!isMember && exclusive}
          className="episode-card-thumbnail-premium"
        />
      )}
      <div className="episode-thumbnail-title p1">{title}</div>
    </div>
  );
};

EpisodeThumbnail.propTypes = {
  episode: shape(episodeShape).isRequired,
  onClick: func.isRequired,
  className: string
};

export default EpisodeThumbnail;
