import React, { useCallback, useEffect } from 'react';
import { shape, func, string, bool } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { useAnalytics } from 'hooks';
import { getFullName } from 'utils/helpers';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import PlayerNavigationBar from 'components/Players/Controls/PlayerNavigationBar';

const NarrativesControls = ({ player, goToNext, title, celebrity, controlsVisible }) => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const { firstName, lastName } = celebrity;

  const onExitPlayer = () => {
    trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
      Seconds: Math.round(player.getPosition()),
      'Celebrity Name': getFullName(firstName, lastName),
      'Narrative Name': title
    });
  };

  const onComplete = useCallback(() => goToNext(), [goToNext]);

  useEffect(() => {
    player.on('complete', onComplete);

    return () => {
      player.off('complete');
    };
  }, [player, onComplete]);

  return (
    <div
      className={cn('custom-controls controls narratives')}
      style={{ visibility: controlsVisible ? 'visible' : 'hidden' }}
    >
      <PlayerNavigationBar
        celebrities={celebrity}
        title={title}
        videoTitle={title}
        shareText={intl.formatMessage(
          { id: 'sharing.narrative' },
          { creator: getFullName(firstName, lastName) }
        )}
        onExitPlayer={onExitPlayer}
      />
    </div>
  );
};

NarrativesControls.propTypes = {
  player: shape({
    on: func.isRequired,
    getState: func.isRequired
  }).isRequired,
  title: string.isRequired,
  goToNext: func,
  celebrity: shape(celebrityInfoShape),
  controlsVisible: bool
};

export default NarrativesControls;
