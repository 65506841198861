import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';

export const useChangingUrl = ({ narratives, visiblePlayer, celebrityId }) => {
  const { replace } = useHistory();
  const { search, state } = useLocation();
  const { prevPath } = state || {};

  useEffect(() => {
    visiblePlayer !== undefined &&
      replace(
        `${routeWithProps(routesPaths.narratives, {
          celebrityId,
          id: narratives[visiblePlayer].id
        })}${search}`,
        { prevPath }
      );
  }, [celebrityId, narratives, prevPath, replace, search, visiblePlayer]);
};

export const scrollIntoView = (index, smooth) => {
  const element = document.getElementById(`jwplayer-${index}`);
  if (element) element.scrollIntoView({ behavior: smooth, block: 'center' });
};
