import httpClient from 'httpClient';

class CelebritiesService {
  getCelebrity(username) {
    return httpClient.get(`/celebrities/${username}`);
  }

  getCelebrityById(id) {
    return httpClient.get(`/celebrities/${id}`);
  }

  getCelebrityMembership(id) {
    return httpClient.get(`/celebrities/${id}/membership_plans`);
  }

  getCelebrityLiveURL(id) {
    return httpClient.get(`/celebrities/${id}/lives`);
  }

  follow(id) {
    return httpClient.post(`/celebrities/${id}/followers`);
  }

  unFollow(id) {
    return httpClient.delete(`/celebrities/${id}/followers`);
  }

  sendDirectMessage(data) {
    return httpClient.post('/celebrities/dms', data);
  }

  deleteCreatorAccount(creatorId) {
    return httpClient.delete(`/celebrities/${creatorId}`);
  }

  getMetrics(id) {
    return httpClient.get(`/celebrities/${id}/analytics`);
  }
}

export default new CelebritiesService();
