import React, { useMemo } from 'react';
import { shape, string, bool, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { getCorrectPlayer, checkSubscription } from 'utils/helpers';
import { celebrityInfoShape } from 'constants/propTypesShapes';
import useProfile from 'hooks/users/useProfile';

import usePlayer from 'hooks/player/usePlayer';
import LiveControls from 'components/Players/Controls/LiveControls';
import OfflineScreen from 'components/celebrity/OfflineScreen/OfflineScreen';
import { useDisappearingControls } from 'hooks';
import ReactPlayer from 'react-player';

const playerDiv = 'livePlayerID';

const LivePlayer = ({ celebrities, liveUrl, offline, isScheduled, sponsorUrl, title }) => {
  const {
    profile: { memberships }
  } = useProfile();

  const isSubscribed = checkSubscription(memberships, celebrities.id);
  const { controlsVisible, handleMouseMove, handleClick } = useDisappearingControls(true);

  const disableAds = useMemo(() => !isScheduled && isSubscribed, [isScheduled, isSubscribed]);

  const { player, error } = usePlayer({
    playerDiv,
    file: liveUrl,
    playerUrl: getCorrectPlayer(disableAds, process.env.PLAYER_PREROLL_ADS_ID),
    showControls: !disableAds,
    controlsVisible,
    mute: true
  });

  if (error || offline) return <OfflineScreen celebrity={celebrities} isScheduled={isScheduled} />;

  return (
    <div
      className={cn('video-container live-video-container', { 'no-pointer': isMobile })}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      {/* <div id={playerDiv} /> */}
      <ReactPlayer
        playsinline
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={liveUrl}
      />
      {player && (
        <LiveControls
          player={player}
          celebrities={celebrities}
          sponsorUrl={sponsorUrl}
          isScheduled={isScheduled}
          title={title}
          controlsVisible={controlsVisible}
        />
      )}
    </div>
  );
};

LivePlayer.propTypes = {
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  liveUrl: string,
  offline: bool,
  isScheduled: bool,
  sponsorUrl: string,
  title: string
};

export default LivePlayer;
