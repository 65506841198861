import httpClient from 'httpClient';
import { applyQueryParams } from 'utils/helpers';
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from './userService';

class SeriesService {
  getOneSeries(id) {
    return httpClient.get(`/series/${id}`);
  }

  addSeries(series) {
    return httpClient.post('/series', series, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => series
    });
  }

  getVideoUrlForJWPlayer(title) {
    return httpClient.get(applyQueryParams('/videos/upload_url', { title }));
  }

  deleteSeries(seriesId) {
    return httpClient.delete(`/series/${seriesId}`);
  }

  reportSeries({ seriesId, params }) {
    return httpClient.post(`/series/${seriesId}/reported_contents`, params);
  }
}

export default new SeriesService();
