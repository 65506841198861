/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useMemo } from 'react';
import { shape, number, string, func, bool } from 'prop-types';
import { useIntl } from 'react-intl';

import { routesPaths } from 'constants/routesPaths';
import { narrativeShape } from 'constants/propTypesShapes';
import { routeWithProps } from 'utils/helpers';
import { useClickOutside, useModal, useNavigation } from 'hooks';

import NarrativeCardInfo from 'components/narratives/NarrativeCardInfo/NarrativeCardInfo';
import VideoThumbnail from 'components/common/VideoThumbnail/VideoThumbnail';
import { OptionsIcon, ShareIcon, TrashIcon } from 'components/icons';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import BlackBg from 'assets/Black.png';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import DeletePublication from 'components/DeletePublication/DeletePublication';
import ReportModal from 'components/ReportModal/ReportModal';

const VERTICAL = 'vertical';
const HORIZONTAL = 'horizontal';

const CLOSED = 0;
const OPENED = 1;
const ICONS_DISPLAYED = 2;

const NarrativeCard = ({
  celebrityId,
  celebrityName,
  narrative,
  refresh,
  username,
  isLoggedUser
}) => {
  const { toggle, isShowing } = useModal();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const { thumbnailsUrls, title, description, vertical, link } = narrative;
  const { goTo } = useNavigation();
  const intl = useIntl();
  const orientation = vertical ? VERTICAL : HORIZONTAL;

  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);

  const watchNarrative = () => {
    goTo(routeWithProps(routesPaths.narratives, { celebrityId, id: narrative.id }));
  };
  const narrativeUrl = useMemo(
    () =>
      `${window.location.origin}${routeWithProps(routesPaths.narratives, {
        celebrityId,
        id: narrative.id
      })}`,
    [celebrityId, narrative.id]
  );

  return (
    <div className={`narrative-card narrative-card-${orientation} `}>
      <VideoThumbnail
        thumbnail={
          narrative?.stage === 'processing' || narrative?.stage === 'post_produce_processing'
            ? BlackBg
            : thumbnailsUrls?.[0]?.src
        }
        videoUrl={link}
        className={`narrative-card-${orientation}-thumbnail ${
          narrative?.stage === 'processing' || narrative?.stage === 'post_produce_processing'
            ? 'processing-card'
            : ''
        }`}
        onClick={narrative?.stage !== 'processing' && watchNarrative}
      >
        {narrative?.stage === 'processing' && <div className="processing-overlay">PROCESSING</div>}
        {narrative?.stage === 'post_produce_processing' && (
          <div className="processing-overlay">POST-PRODUCING</div>
        )}
      </VideoThumbnail>

      <div className={`narrative-card-${orientation}-gradient`} />
      <div className={`narrative-card-${orientation}-info`}>
        <NarrativeCardInfo title={title} description={description} />
      </div>
      <div className="sharing-options" ref={ref}>
        {optionsStatus == OPENED && (
          <>
            {!isLoggedUser && (
              <button className="report-button" onClick={reportToggle}>
                Report
              </button>
            )}
            {isLoggedUser && <IconWithBackground icon={<TrashIcon />} onClick={toggle} isSmall />}
            <IconWithBackground
              icon={<ShareIcon />}
              onClick={() => setOptionsStatus(ICONS_DISPLAYED)}
              isSmall
            />
          </>
        )}
        {optionsStatus == ICONS_DISPLAYED && (
          <SharingBlock
            onClose={() => setOptionsStatus(CLOSED)}
            shareUrl={narrativeUrl}
            videoTitle={narrative.title}
            shareText={intl.formatMessage({ id: 'sharing.narrative' }, { creator: celebrityName })}
          />
        )}
        {(optionsStatus == CLOSED || optionsStatus == OPENED) && (
          <IconWithBackground
            icon={<OptionsIcon />}
            onClick={() => setOptionsStatus(optionsStatus == OPENED ? CLOSED : OPENED)}
            isSmall
          />
        )}
        {isShowing && (
          <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
            <DeletePublication
              type="narrative"
              refresh={refresh}
              celebrityId={celebrityId}
              id={narrative.id}
              username={username}
            />
          </Modal>
        )}
        {reportShowing && (
          <Modal
            hide={reportToggle}
            isShowing={reportShowing}
            topModal
            zIndex={zIndexModalThird}
            className="original-modal"
          >
            <ReportModal title="Report Narrative" type="isNarrative" id={narrative.id} />
          </Modal>
        )}
      </div>
    </div>
  );
};

NarrativeCard.propTypes = {
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  narrative: shape(narrativeShape).isRequired,
  refresh: func,
  username: string,
  isLoggedUser: bool
};

export default NarrativeCard;
