import React from 'react';
import { shape, string, arrayOf, oneOfType, func } from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { celebrityInfoShape } from 'constants/propTypesShapes';
import { getPlayerUrl } from 'utils/helpers';
import { useDisappearingControls } from 'hooks';

import usePlayer from 'hooks/player/usePlayer';
import MiscControls from 'components/Players/Controls/MiscControls';
import ReactPlayer from 'react-player';

const playerDiv = 'trailerPlayerID';

const MiscPlayer = ({ celebrities, videoUrl, type, title, description, goBack }) => {
  const { controlsVisible, handleClick, handleMouseMove } = useDisappearingControls(true);

  const { player } = usePlayer({
    playerDiv,
    file: videoUrl,
    type,
    playerUrl: getPlayerUrl(process.env.PLAYER_WITHOUT_ADS_ID),
    showControls: false,
    controlsVisible,
    title,
    description
  });

  return (
    <div
      className={cn('video-container misc-video-container', { 'no-pointer': isMobile })}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      {/* <div id={playerDiv} /> */}
      <ReactPlayer
        playsinline
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={videoUrl}
      />
      {player && (
        <MiscControls
          player={player}
          celebrities={celebrities}
          title={title}
          description={description}
          goBack={goBack}
          controlsVisible={controlsVisible}
        />
      )}
    </div>
  );
};

MiscPlayer.propTypes = {
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  description: string,
  title: string,
  type: string,
  videoUrl: string.isRequired,
  goBack: func.isRequired
};

export default MiscPlayer;
