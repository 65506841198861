import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStatus, LOADING, ERROR, SUCCESS } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';

import { createMembership, cancelMembership } from 'state/actions/membershipActions';
import { getCelebrityMembership } from 'state/actions/celebritiesActions';

import { useToast, useAnalytics } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { getFullName } from 'utils/helpers';

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { status, error } = useStatus(getCelebrityMembership);
  const { status: cancelStatus, error: cancelError } = useStatus(cancelMembership);
  const { showErrorToast, showToast } = useToast();
  const { requestProfile } = useProfile();
  const { trackEvent } = useAnalytics();
  const [canceledMembership, setCanceledMembership] = useState();

  const getMembership = id => {
    dispatch(getCelebrityMembership(id));
  };

  const newMembership = (celebrityId, membershipId, creditCardId) => {
    trackEvent(MIXPANEL_EVENTS.completeSubscriptionClicked);
    dispatch(createMembership(celebrityId, membershipId, creditCardId));
  };

  const reset = () => {
    dispatch(getCelebrityMembership.reset());
  };

  const cancel = async membership => {
    const {
      id: membershipId,
      celebrity: { id: celebrityId }
    } = membership;
    setCanceledMembership(membership);
    await dispatch(cancelMembership(celebrityId, membershipId));
    requestProfile();
  };

  useEffect(() => {
    if (cancelStatus === SUCCESS) {
      showToast(intl.formatMessage({ id: 'membership.cancel.success' }));
      const {
        celebrity: { firstName, lastName },
        membershipPlan: { name }
      } = canceledMembership;
      setCanceledMembership();
      trackEvent(MIXPANEL_EVENTS.membershipCanceled, {
        'Creator Name': getFullName(firstName, lastName),
        'Membership type': name
      });
      dispatch(cancelMembership.reset());
    }
    if (cancelStatus === ERROR) {
      showErrorToast(cancelError);
      setCanceledMembership();
      dispatch(cancelMembership.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelStatus]);

  return {
    error,
    loading: status === LOADING,
    membershipPlans: useSelector(({ celebrities: { membershipPlans } }) => membershipPlans),
    getMembership,
    newMembership,
    createMembershipStatus: useStatus(createMembership),
    cancelMembership: cancel,
    reset,
    cancelStatus
  };
};
