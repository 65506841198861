/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { func, shape } from 'prop-types';
import { useDispatch } from 'react-redux';

import { serieTabs, MIXPANEL_EVENTS } from 'constants/constants';
import { serieShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { getOriginalLabel, routeWithProps } from 'utils/helpers';
import { useKeepWatching } from 'hooks/useSeries';
import { useIsMobile, useClickOutside, useAnalytics, useNavigation } from 'hooks';
import useOneSeries from 'hooks/useOneSeries';
import { getOneSeries } from 'state/actions/seriesActions';

import Tabs from 'components/common/Tabs/Tabs';
import About from 'components/serieLanding/About/About';
import EpisodesGrid from 'components/episodes/EpisodesGrid/EpisodesGrid';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import SerieInfo from 'components/series/SerieInfo/SerieInfo';
import Button from 'components/common/Button/Button';
import Loading from 'components/common/Loading';
import Close from 'components/common/Close/Close';

import { white, black40 } from 'styles/common/_constants.scss';
import PlayIconCircle from 'components/common/PlayIconCircle/PlayIconCircle';

const SerieLanding = ({ serie, hide }) => {
  const { id, title, desktopImageUrl, mobileImageUrl, episodesCount } = serie;
  const { DETAILS, EPISODES } = serieTabs;

  const { goToWithSearch } = useNavigation();
  const watchedEpisode = useKeepWatching(id);

  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();

  const { serie: serieDetails, loading } = useOneSeries();
  const { episodes } = serieDetails;

  const showLoading = serie.id !== serieDetails?.id || loading;

  // TODO: Improve click outside of modal logic
  const ref = useClickOutside(hide, id);

  const [selectedTab, setSelectedTab] = useState(DETAILS.id);

  useEffect(() => {
    serie.id !== serieDetails?.id && dispatch(getOneSeries(id));
    serie.id == serieDetails?.id &&
      trackEvent(MIXPANEL_EVENTS.seriesDetails, { seriesName: title });
  }, [dispatch, id, serie.id, serieDetails, title, trackEvent]);

  const renderContent = tab => {
    switch (tab) {
      case DETAILS.id:
        return <About serie={serieDetails} />;
      case EPISODES.id:
        return (
          <EpisodesGrid
            serieId={id}
            episodes={episodes}
            className="serie-landing-grid scrollable-container"
            mobileColumn
          />
        );
      default:
    }
  };

  const watchEpisode = () => {
    const { id: wacthedId, time } = watchedEpisode || {};
    const episode = watchedEpisode ? wacthedId : episodes[0].id;
    const route = routeWithProps(routesPaths.series, { id, episode });
    let search = '';
    if (watchedEpisode) {
      search = `t:${time}`;
    }
    goToWithSearch(route, search);
  };

  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        <BackgroundOpacity opacity={0.5} background={desktopImageUrl || mobileImageUrl} />
        <div className="serie-landing-header-gradient" />
        <Close onClick={hide} color={isMobile ? white : black40} />
        <div className="layer serie-landing-header-content">
          <SerieInfo serie={serie} hideDescription className="header-info" />
          <div className="flex-column header-right-column">
            <Button
              labelId="episodes.watch"
              type="primary"
              size="xsmall"
              className="watch-serie"
              onClick={watchEpisode}
              lightFont
            />
            <div className="p2-16 serie-label">{getOriginalLabel(episodesCount)}</div>
          </div>
        </div>
        {isMobile && (
          <div className="play-button" onClick={watchEpisode}>
            <PlayIconCircle />
          </div>
        )}
      </div>
      <div className="serie-landing-content">
        {showLoading ? (
          <Loading />
        ) : (
          <>
            <Tabs
              tabs={Object.values(serieTabs)}
              selectedTab={selectedTab}
              onTabSelect={setSelectedTab}
              isVertical={!isMobile}
              className="serie-landing-tabs"
            />
            <div className="separator" />
            {renderContent(selectedTab)}
          </>
        )}
      </div>
      <div className="top-gradient" />
      <div className="bottom-gradient" />
    </div>
  );
};

SerieLanding.propTypes = {
  serie: shape(serieShape),
  hide: func.isRequired
};

export default SerieLanding;
