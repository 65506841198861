/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import { useNavigation, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import { addSeries, getTrailerUrlForJWPlayer } from 'state/actions/seriesActions';
import { parseHashtags, routeWithProps } from 'utils/helpers';
import { clearEpisodes } from 'state/actions/episodesActions';
import { routesPaths } from 'constants/routesPaths';
import parseError from 'utils/parseError';
import UserService from 'services/userService';

export default () => {
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const username = useRef();
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showToast } = useToast();
  const [series, setSeries] = useState({});
  const { status, error } = useStatus(addSeries);
  const { trailerData } = useSelector(({ seriesUpload: { trailerData } }) => ({ trailerData }));
  const { episodes } = useSelector(({ episodes: { episodes } }) => ({ episodes }));
  const [completedVideos, setCompletedVideos] = useState(0);
  const [uploadedVideos, setUploadedVideos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const totalVideoRef = useRef();
  const videoDoneRef = useRef();
  const totalVideos = episodes.length + 1;
  const { status: getTrailerUrlStatus, error: getTrailerUrlError } = useStatus(
    getTrailerUrlForJWPlayer
  );

  const addNewSeries = async () => {
    const { categoryIds, description, hashtags, title } = series;
    const data = {
      description,
      title,
      banner_media_id: trailerData?.mediaId
    };
    const episodesList = episodes.map(
      (
        {
          creditsStartTime,
          description,
          earlyReleaseDateTime,
          exclusive,
          mediaId,
          membersPrice,
          nonMembersPrice,
          releaseDateTime,
          title
        },
        index
      ) => ({
        credits_start_time: creditsStartTime,
        early_release_date_time: earlyReleaseDateTime,
        media_id: mediaId,
        members_price: membersPrice === 0 ? null : Number(membersPrice).toFixed(2),
        non_members_price: nonMembersPrice === 0 ? null : Number(nonMembersPrice).toFixed(2),
        release_date_time: releaseDateTime,
        position: index,
        description,
        exclusive,
        title
      })
    );
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(`series[${key}]`, data[key]);
    });

    formData.append('series[logo_image]', series.logo_image);
    formData.append('series[desktop_image]', series.desktop_image);
    formData.append('series[mobile_image]', series.mobile_image);

    formData.append('series[category_ids]', JSON.stringify(categoryIds));
    formData.append('series[hashtags]', JSON.stringify(hashtags));
    formData.append('series[episodes]', JSON.stringify(episodesList));
    await dispatch(addSeries(formData));
  };

  useEffect(() => {
    if (getTrailerUrlStatus === SUCCESS) {
      addNewSeries();
      return;
    }

    if (getTrailerUrlStatus === ERROR) {
      showErrorToast(getTrailerUrlError);
      dispatch(getTrailerUrlForJWPlayer.reset());
      setLoading(false);
    }
  }, [getTrailerUrlStatus]);

  const onSubmit = useCallback(
    data => {
      const categoryIds = data.categoryIds.map(({ value }) => value);
      data.hashtags = parseHashtags(data.description);
      data.categoryIds = categoryIds;
      setSeries(data);
      dispatch(getTrailerUrlForJWPlayer(data.trailer));
    },
    [dispatch]
  );

  const resetDispatchData = () => {
    dispatch(clearEpisodes());
    dispatch(addSeries.reset());
    dispatch(getTrailerUrlForJWPlayer.reset());
  };

  const onCompleteVideoUpload = () => {
    resetDispatchData();
    showToast('Publication successfully uploaded!');
    setLoading(false);
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: username.current.toLowerCase()
      })
    );
  };

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (completedVideos === totalVideos) {
      onCompleteVideoUpload();
      videoDoneRef.current = 2;
    }
  }, [completedVideos]);

  const uploadVideosInJWPlayer = async videos => {
    try {
      videoDoneRef.current = 1;
      setUploadProgress(0);
      setUploadedVideos(0);
      totalVideoRef.current = videos.length;

      for (let index = 0; index < videos.length; index++) {
        const video = videos[index];
        const formData = new FormData();
        formData.append('file', video.video);

        await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', video.uploadUrl);

          xhr.upload.onprogress = event => {
            if (event.lengthComputable) {
              const progress = Math.round((event.loaded / event.total) * 100);
              setUploadProgress(progress);
            }
          };

          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              setUploadedVideos(prevCount => prevCount + 1);
              setCompletedVideos(prev => prev + 1);
              resolve(xhr.response);
            } else {
              reject(new Error('Upload failed'));
            }
          };

          xhr.onerror = () => reject(new Error('Upload failed'));

          xhr.send(formData);
        });

        setUploadProgress(0);
      }
    } catch (error) {
      showErrorToast('Something went wrong, try again please!');
    }
  };

  useEffect(() => {
    if (status === SUCCESS) {
      setLoading(true);
      const videosToUpload = [
        {
          video: series.trailer,
          mediaId: trailerData?.mediaId,
          uploadUrl: trailerData?.uploadUrl
        }
      ];
      episodes.forEach(({ mediaId, uploadUrl, seriesVideo }) => {
        videosToUpload.push({
          video: seriesVideo,
          mediaId,
          uploadUrl
        });
      });
      uploadVideosInJWPlayer(videosToUpload);
    }
  }, [status, dispatch]);

  return {
    onSubmit,
    status,
    loading,
    error: capitalize(error),
    uploadProgress,
    uploadedVideos,
    totalVideos: totalVideoRef.current,
    uploadingStatus: videoDoneRef.current
  };
};
