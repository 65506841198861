/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import cn from 'classnames';

import { useToggle, useClickOutside, useSession, useIsMobile, useNavigation } from 'hooks';
import useNotifications from 'hooks/notifications/useNotifications';
import { LogoIcon, OptionsIcon } from 'components/icons';
import { invRoutes, routesPaths } from 'constants/routesPaths';
import { getUserPicture } from 'utils/dataHelpers';

import UserDropdown from 'components/header/UserDropdown/UserDropdown';
import NavbarDropdown from 'components/header/NavbarDropdown/NavbarDropdown';
import Modal from 'components/common/Modal/Modal';
import Notifications from 'components/notifications/Notifications/Notifications';
import BellIcon from 'components/icons/others/BellIcon';
import HamburgerMenu from 'components/header/HamburgerMenu';
import UploadIcon from 'assets/icons/Upload/Upload.png';
import MetricsIcon from 'assets/icons/Metrics/Metrics.png';

import Badge from 'components/common/Badge/Badge';
import { USER_TYPES } from 'hooks/useSession';
import useProfile from 'hooks/users/useProfile';
import Button from 'components/common/Button/Button';
import OriginalDropdown from '../OriginalDropdown/OriginalDropdown';

const routesNames = defineMessages({
  home: { id: 'navbar.home' }
});

const Header = () => {
  const intl = useIntl();
  const { authenticated, user } = useSession();
  const { profile } = useProfile();
  const { pathname } = useLocation();
  const { badge, requestNotifications } = useNotifications();
  const { goTo } = useNavigation();

  const [showBackground, setShowBackground] = useState(false);
  const [showUserDropdown, toggleUserDropdown] = useToggle(false);
  const [showNotifications, toggleNotifications] = useToggle(false);
  const [showNavbarDropdown, toggleNavbarDropdown] = useToggle(false);
  const [showOriginalDropdown, toggleOriginalDropdown] = useToggle(false);
  const [showNotificationsModal, toggleNotificationsModal] = useToggle(false);
  const userDropdownRef = useClickOutside(toggleUserDropdown, showUserDropdown);
  const navbarDropdownRef = useClickOutside(toggleNavbarDropdown, showNavbarDropdown);
  const isMobile = useIsMobile();
  const notificationsRef = useClickOutside(toggleNotifications, showNotifications);
  const userPicture = useMemo(() => getUserPicture(user?.profileImageUrl), [user?.profileImageUrl]);

  const handleScroll = () => setShowBackground(window.pageYOffset > 0);

  useEffect(() => {
    window && window.addEventListener('scroll', handleScroll);
    if (authenticated) {
      requestNotifications();
    }
    return () => window && window.removeEventListener('scroll', handleScroll);
  }, [authenticated, requestNotifications]);

  const onAvatarClick = () => {
    isMobile && authenticated ? goTo(routesPaths.account) : toggleUserDropdown();
  };
  const goToBecomeCreator = () => goTo(routesPaths.becomeCreator);

  const handleCloseDropDown = () => {
    toggleOriginalDropdown();
  };

  const handleMetrics = () => goTo(routesPaths.metrics);

  const renderCreatorButton = () => {
    return (
      <>
        {isMobile ? (
          <div className="notification-icon">
            <img src={UploadIcon} alt="upload-icon" onClick={goToBecomeCreator} />
          </div>
        ) : (
          <div className="notification-icon">
            <Button className="creator-button" onClick={goToBecomeCreator}>
              Become a creator
            </Button>
          </div>
        )}
      </>
    );
  };
  return (
    <header className={cn('header', { showBackground })}>
      <div className="header-block">
        <HamburgerMenu />
        <Link to={routesPaths.home}>
          <LogoIcon className="identifi-logo" />
        </Link>
        <div className="navbar">
          {Object.keys(routesNames).map(route => (
            <div
              key={route}
              className={cn('navbar-item', { selected: route === invRoutes[pathname] })}
            >
              <Link to={routesPaths[route]}>
                <h5>{intl.formatMessage(routesNames[route])}</h5>
              </Link>
            </div>
          ))}
          <div className="navbar-item" ref={navbarDropdownRef}>
            <div className="options-icon" onClick={toggleNavbarDropdown}>
              <OptionsIcon />
            </div>
            {showNavbarDropdown && <NavbarDropdown toggle={toggleNavbarDropdown} />}
          </div>
        </div>
      </div>
      <div className="flex-center">
        {authenticated && profile?.type === USER_TYPES?.celebrity && (
          <div className="notification-icon">
            <img src={MetricsIcon} alt="metrics-icon" onClick={handleMetrics} />
          </div>
        )}
        {authenticated &&
          (profile?.type === USER_TYPES?.user ? (
            renderCreatorButton()
          ) : (
            <div>
              <div className="notification-icon">
                <img src={UploadIcon} alt="upload-icon" onClick={toggleOriginalDropdown} />
              </div>
              {showOriginalDropdown && (
                <OriginalDropdown handleCloseDropDown={handleCloseDropDown} />
              )}
            </div>
          ))}

        {!isMobile && authenticated && (
          <div className="notification-icon" ref={notificationsRef}>
            <BellIcon onClick={toggleNotifications} showBadge={badge} />
            {showNotifications && <Notifications />}
          </div>
        )}
        <div ref={userDropdownRef}>
          <div className="avatar" onClick={onAvatarClick}>
            <div className="avatar-container">{userPicture}</div>
            {authenticated && isMobile && badge && <Badge />}
          </div>
          {showUserDropdown && (
            <UserDropdown
              toggleModal={toggleUserDropdown}
              toggleNotifications={toggleNotificationsModal}
            />
          )}
        </div>
      </div>
      {isMobile && (
        <Modal hide={toggleNotificationsModal} isShowing={showNotificationsModal}>
          <Notifications isModal />
        </Modal>
      )}
    </header>
  );
};

export default Header;
