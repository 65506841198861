import React, { useState, useCallback } from 'react';
import { arrayOf, shape, bool, number } from 'prop-types';

import { narrativeShape, celebrityInfoShape } from 'constants/propTypesShapes';
import { showNarrativeAd } from 'utils/helpers';
import { useNavigation } from 'hooks';

import NarrativesPlayer from 'components/Players/NarrativesPlayer';

import { useChangingUrl } from '../hooks';

const NarrativesHorizontalSlider = ({ narratives, native, initialIndex, celebrity }) => {
  const [selectedNarrative, setSelectedNarrative] = useState(initialIndex);
  const firstNarrativeSelected = selectedNarrative === 0;
  const lastNarrativeSelected = selectedNarrative === narratives.length - 1;
  const [index, setIndex] = useState(0);
  const { goBack } = useNavigation();

  useChangingUrl({ narratives, visiblePlayer: selectedNarrative, celebrityId: celebrity.id });

  const next = useCallback(() => {
    setIndex(index + 1);
    lastNarrativeSelected ? goBack() : setSelectedNarrative(selectedNarrative + 1);
  }, [selectedNarrative, lastNarrativeSelected, index, goBack]);

  const previous = useCallback(() => {
    setIndex(index + 1);
    !firstNarrativeSelected && setSelectedNarrative(selectedNarrative - 1);
  }, [selectedNarrative, firstNarrativeSelected, index]);

  const currentNarrative = narratives[selectedNarrative];

  return (
    <div className="slider-container">
      <NarrativesPlayer
        key={currentNarrative.id}
        native={native}
        goToNext={next}
        goToPrevious={previous}
        className="slide"
        indexId={`jwplayer-${currentNarrative.id}`}
        narrative={currentNarrative}
        play
        celebrity={celebrity}
        showAds={showNarrativeAd(index)}
        showNextArrow={!lastNarrativeSelected}
        showPreviousArrow={!firstNarrativeSelected}
        index={showNarrativeAd(index)}
      />
    </div>
  );
};

NarrativesHorizontalSlider.propTypes = {
  narratives: arrayOf(shape(narrativeShape)).isRequired,
  native: bool,
  initialIndex: number,
  celebrity: shape(celebrityInfoShape)
};

export default NarrativesHorizontalSlider;
