/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';
import Button from 'components/common/Button/Button';

const PublishModal = ({ hide, id, handlePublish }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  const handleButtonClick = value => {
    handlePublish(value);
    hide();
  };
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        Publish Options <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content" style={{ backgroundColor: '#1e1e1e' }}>
        <Button className="submit-button" onClick={() => handleButtonClick(true)}>
          Post-Produce
        </Button>
        <div className="info">
          This option will send your creation to our production team for some pro touches and we
          will post it for you.
        </div>
        <div class="container">
          <div class="line"></div>
          <div class="text">Or</div>
          <div class="line"></div>
        </div>
        <Button className="publish-button" onClick={() => handleButtonClick(false)}>
          Publish Now
        </Button>
      </div>
    </div>
  );
};

export default PublishModal;
