/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
import React from 'react';
import { object } from 'prop-types';

import Button from 'components/common/Button/Button';
import InfoIcon from 'assets/icons/Info/Info.png';
import { useIsMobile, useModal } from 'hooks';
import Modal from 'components/common/Modal/Modal';
import InfoModal from './InfoModal';

const UserHeader = ({ metricsData }) => {
  const isMobile = useIsMobile();
  const { toggle, isShowing } = useModal();
  return (
    <>
      <div className="subscribe-profile-info">
        <div className="metrics-info account-profile">
          <div className="user-header">
            <div className="user-info">
              <h2>{metricsData?.totalSuscriptors}</h2>
              <h4 className="small">Total subscribers</h4>
            </div>
          </div>
          <div className="user-info">
            <h2>
              ${metricsData?.revenue}{' '}
              <img src={InfoIcon} alt="info" style={{ cursor: 'pointer' }} onClick={toggle} />
            </h2>
            <h4 className="small">Available balance</h4>
          </div>
        </div>
        <div className="id-container">
          <Button
            labelId="request payment"
            type="tetriary"
            className="request-btn"
            smallBoldFont={isMobile}
            disabled
          />
        </div>
        <Modal hide={toggle} isShowing={isShowing} className="original-modal">
          <InfoModal />
        </Modal>
      </div>
    </>
  );
};

UserHeader.propTypes = {
  metricsData: object
};

export default UserHeader;
