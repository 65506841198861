import React from 'react';
import { useHistory } from 'react-router-dom';

import { accountTabs } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { getTabNameFromId } from 'utils/dataHelpers';
import { routeWithProps } from 'utils/helpers';

import Subscriptions from 'components/account/Subscriptions/Subscriptions';
import PaymentMethods from 'components/account/PaymentMethods/PaymentMethods';
import Settings from 'components/account/Settings/Settings';

export default () => {
  const { replace } = useHistory();

  const renderAccountContent = (tab, isModal = false) => {
    const { SUBSCRIPTIONS, PAYMENTS, SETTINGS } = accountTabs;
    switch (tab) {
      case SUBSCRIPTIONS.id:
        return <Subscriptions isModal={isModal} />;
      case PAYMENTS.id:
        return <PaymentMethods isModal={isModal} />;
      case SETTINGS.id:
        return <Settings isModal={isModal} />;
      default:
    }
  };

  const changeUrl = id => {
    const tab = id ? getTabNameFromId(accountTabs, id) : '';
    replace(routeWithProps(routesPaths.account, { tab }));
  };

  return {
    renderAccountContent,
    changeUrl
  };
};
