import React from 'react';
import cn from 'classnames';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { defineMessages, useIntl } from 'react-intl';
import AddNarrativeForm from 'components/Narratives/AddNarrativeForm';
import { BackArrowIcon } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { routesPaths } from 'constants/routesPaths';

const messages = defineMessages({
  title: { id: 'title.uploadNarrative' }
});

const AddNarratives = () => {
  const history = useHistory();
  const intl = useIntl();
  return (
    <div className={cn('become-creator')}>
      <BackgroundOpacity opacityColor="black" opacity={0.5} />
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container" style={{ overflow: 'hidden' }}>
            <div className="back-button" onClick={() => history.push(routesPaths.home)}>
              <BackArrowIcon />
            </div>
            <h1 className="title">{intl.formatMessage(messages.title)}</h1>
            <AddNarrativeForm />
          </div>
        </div>
      </div>
    </div>
  );
};

AddNarratives.loadData = async () => {};

export default AddNarratives;
