import React, { useEffect } from 'react';
import { object } from 'prop-types';

import { getCelebrity } from 'state/actions/celebritiesActions';
import { useDispatch, useAnalytics, useCelebrity } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';

import CelebrityBanner from 'components/ids/CelebrityBanner/CelebrityBanner';
import CelebrityContent from 'components/ids/CelebrityContent/CelebrityContent';
import Loading from 'components/common/Loading';
import NotFoundPage from 'pages/NotFoundPage';
import useMembership from 'hooks/celebrities/useMembership';

const CelebrityIdPage = ({ match }) => {
  const {
    params: { username }
  } = match;
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get('tab');
  const { trackEvent } = useAnalytics();
  const getCelebrityRequest = useDispatch(getCelebrity);
  const { celebrity, error, reset, loading } = useCelebrity();
  const { getMembership } = useMembership();
  useEffect(() => {
    getCelebrityRequest(username);
    window && window.scrollTo(0, 0);
    return () => {
      reset();
    };
  }, [username]);
  useEffect(() => {
    if (celebrity.id) {
      getMembership(celebrity.id);
      trackEvent(MIXPANEL_EVENTS.creatorID, {
        name: `${celebrity.firstName} ${celebrity.lastName}`
      });
    }
  }, [celebrity]);

  if (error) return <NotFoundPage />;

  if (loading || !celebrity.id)
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  return (
    <>
      <CelebrityBanner celebrity={celebrity} />
      {celebrity.id && <CelebrityContent celebrity={celebrity} tab={tab} />}
    </>
  );
};

CelebrityIdPage.loadData = async (store, match) => {
  const {
    params: { username }
  } = match;
  await store.dispatch(getCelebrity(username));
};

CelebrityIdPage.propTypes = {
  match: object.isRequired
};

export default CelebrityIdPage;
