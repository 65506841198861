/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';
import useReportNarrative from 'hooks/narrative/useReportNarrative';
import useReportSeries from 'hooks/series/useReportSeries';

const ReportModal = ({ hide, id, title, type }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  const reportOptions = [
    'Scam or fraud',
    'Hate speech or symbol',
    'Intellectual property violation',
    'Other'
  ];
  const reportContent = useReportNarrative();
  const reportSeries = useReportSeries();
  const onReport = async data => {
    if (type === 'isNarrative') {
      await reportContent({
        narrativeId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
      hide();
    } else {
      await reportSeries({
        seriesId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
      hide();
    }
  };

  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        {title} <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <ul>
          {reportOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => onReport(option)}
              style={{ cursor: 'pointer', paddingLeft: '20px' }}
            >
              <div className="d-flex">
                <div>{option}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ReportModal;
