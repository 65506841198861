/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';

export default ({
  config,
  playerDiv,
  file,
  type,
  playerUrl,
  title,
  description,
  mute = false,
  controlsVisible
}) => {
  const [player, setPlayer] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const HIDDEN_CONTROLS_STYLE = 'jw-flag-controls-hidden';
    const INACTIVE_USER_STYLE = 'jw-flag-user-inactive';

    const playerElement = document.getElementById(playerDiv);

    if (controlsVisible) {
      playerElement?.classList.remove(HIDDEN_CONTROLS_STYLE);
      playerElement?.classList.remove(INACTIVE_USER_STYLE);
    } else {
      playerElement?.classList.add(HIDDEN_CONTROLS_STYLE);
      playerElement?.classList.add(INACTIVE_USER_STYLE);
    }
  }, [controlsVisible, playerDiv]);

  useEffect(() => {
    if (player) {
      player.on('error', () => setError(true));
    }
    return () => {
      player && player.off('error');
    };
  }, [player]);

  useEffect(() => {
    const setup = () => {
      const jw = window?.jwplayer(playerDiv);
      if (jw?.setup) {
        jw.setup({
          mute,
          playlist: [{ file, type, title, description }],
          height: '100%',
          width: '100%',
          autostart: 'viewable',
          related: {
            displayMode: 'none'
          },
          displaytitle: false,
          displaydescription: false,
          ...config
        });
        setPlayer(jw);
      }
    };

    const previousPlayer = document.getElementById('identifi-player');
    if (previousPlayer) previousPlayer.remove();
    const jwPlayerScript = document?.createElement('script');
    jwPlayerScript.id = 'identifi-player';
    jwPlayerScript.src = playerUrl;
    document.head.appendChild(jwPlayerScript);

    jwPlayerScript.onload = setup;

    return () => {
      jwPlayerScript && jwPlayerScript.remove();
    };
  }, [file, playerDiv, playerUrl]);

  return { player, error };
};
